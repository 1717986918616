/*	js-fadeUp	*/
.js-fadeUp {
  &.js-order01 {
    transform: translateY(70px);
    transition: 1s ease;
    opacity: 0;
  }
  &.js-order02 {
    transform: translateY(70px);
    transition: 1s ease .2s;
    opacity: 0;
  }
  &.js-order03 {
    transform: translateY(70px);
    transition: 1s ease .4s;
    opacity: 0;
  }
  &.js-order04 {
    transform: translateY(70px);
    transition: 1s ease .6s;
    opacity: 0;
  }
  &.js-order05 {
    transform: translateY(70px);
    transition: 1s ease .8s;
    opacity: 0;
  }
  &.js-order06 {
    transform: translateY(70px);
    transition: 1s ease 1s;
    opacity: 0;
  }
  &.scrollin {
    &.js-order01 {
      transform: translateY(0);
      opacity: 1;
    }
    &.js-order02 {
      transform: translateY(0);
      opacity: 1;
    }
    &.js-order03 {
      transform: translateY(0);
      opacity: 1;
    }
    &.js-order04 {
      transform: translateY(0);
      opacity: 1;
    }
    &.js-order05 {
      transform: translateY(0);
      opacity: 1;
    }
    &.js-order06 {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
