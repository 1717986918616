$mq-breakpoints: (
  'pc': 'min-width: 768px',
  'sp': 'max-width: 767px',
);

@mixin mq($breakpoint: sp) {
  @media screen and (#{map-get($mq-breakpoints, $breakpoint)}) {
    @content;
  }
}

@function get_vw($size, $viewport: 375) {
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@function get_vw_pc($size, $viewport: 1920) {
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@mixin fz_vw($font_size:10){
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}

$gothic: 'Noto Sans JP', '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'ヒラギノ角ゴ ProN W3', 'メイリオ', Meiryo, sans-serif;

$mincho: 'Noto Serif JP', '游明朝体', YuMincho, '游明朝', 'Yu Mincho', 'Hiragino Mincho ProN', 'Hiragino Mincho Pro', 'HGS明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;