.footer {
  background: #000;
  position: relative;
  width: 100%;
  height: 80px;
  z-index: 10;
  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    max-width: 1470px;
    padding: 0 15px;
    width: 100%;
    height: 80px;
    &__item {
      color: #8B8B8B;
      font-size: 18px;
      &.is_font_small {
        font-size: 14px;
      }
      a {
        cursor: pointer;
        display: flex;
        align-items: center;
      }
      img {
        margin-right: 10px;
        width: auto;
      }
    }
  }
  &__ico {
    &__return {
      height: 23px;
    }
    &__save {
      height: 30px;
    }
    &__contact {
      height: 30px;
    }
  }
  @include mq {
    height: 40px;
    &__list {
      padding: 0;
      height: 40px;
      &__item {
        font-size: 14px;
        width: 33.3%;
        a {
          justify-content: center;
        }
        img {
          margin-right: 5px;
        }
      }
    }
    &__ico {
      &__return {
        height: 18px;
      }
      &__save {
        height: 18px;
      }
      &__contact {
        height: 18px;
      }
    }
  }
}
