.loading {
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  &__box {
    text-align: center;
  }
  &__imgBox {
    animation: 2.5s linear infinite rotation;
    margin: auto;
    width: 120px;
    height: 120px;
  }
  &__txt {
    color: #888;
    font-size: 18px;
    margin-top: 40px;
  }
  @include mq {
    &__imgBox {
      width: 74px;
      height: 74px;
    }
    &__txt {
      font-size: 11px;
      margin-top: 25px;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0);
  }
}
