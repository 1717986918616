.top_article {
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  &::before {
    background-image: url(../img/bg/bg03.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: 1s;
    opacity: 0;
  }
  &.is_bg_show {
    position: relative;
    &::before {
      opacity: 1;
    }
  }
  &__bg {
    position: relative;
    &::before {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: 1s;
      opacity: 0;
    }
  }
  &__bg3 {
    &::before {
      background-image: url(../img/bg/bg03.jpg);
    }
  }
  &__bg4 {
    &::before {
      background-image: url(../img/bg/bg04.jpg);
    }
  }
  &__bg5 {
    &::before {
      background-image: url(../img/bg/bg05.jpg);
    }
  }
  &__bg6 {
    &::before {
      background-image: url(../img/bg/bg06.jpg);
    }
  }
  &__box {
    color: #fff;
    text-align: center;
    margin-bottom: 100px;
    max-width: 950px;
    width: 100%;
  }
  &__txtBox {
    opacity: 0;
    transition: 1.5s;
  }
  &__ttl {
    font-family: 'Arial', sans-serif;
    font-size: 60px;
    span {
      display: block;
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
  &__lead {
    font-size: 18px;
    margin-top: 20px;
  }
  &__slider {
    margin: 60px auto 0;
    width: 80%;
    height: 500px;
    &__wrap {
      transition: 1.5s;
      opacity: 0;
    }
    &__item {
      background: #000;
      color: #fff;
      cursor: pointer;
      display: flex;
      width: 420px;
      height: 420px;
      img {
        width: 100%;
        height: auto;
      }
      &.roundabout-in-focus {
        a {
          &:hover {
            color: #E6E6E6;
          }
        }
      }
      a {
        &:hover {
          color: #BFBFBF;
        }
      }
    }
    &__txtBox {
      color: #BFBFBF;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 22px 0 32px 7%;
      position: relative;
      width: 50%;
    }
    &__imgBox {
      display: flex;
      align-items: center;
      padding: 22px 32px 38px 20px;
      width: 50%;
    }
    &__ttl {
      font-size: 1.5em;
    }
    &__txt, &__price {
      font-size: .8em;
      margin-top: 6.2%;
    }
  }
  &__linkList {
    width: 80%;
    &__item {
      font-size: .9em;
      & + & {
        margin-top: 15%;
      }
      a {
        display: block;
        position: relative;
        transition: 0s;
        &::before {
          background: url(../img/ico/ico_right01.svg) right center no-repeat;
          background-size: 100% 100%;
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 12%;
          height: 55%;
        }
      }
    }
  }
  &__btm {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: absolute;
    bottom: 50px;
    left: 50px;
    width: calc(100% - 100px);
  }
  &__btmTxt {
    color: #fff;
    font-size: 18px;
  }
  &__copyright {
    color: #fff;
    font-size: 11px;
  }
  @include mq {
    &__bg3 {
      background-image: url(../img/bg/bg03_sp.jpg);
    }
    &__bg4 {
      background-image: url(../img/bg/bg04_sp.jpg);
    }
    &__bg5 {
      background-image: url(../img/bg/bg05_sp.jpg);
    }
    &__bg6 {
      background-image: url(../img/bg/bg06_sp.jpg);
    }
    &__box {
      color: #fff;
      text-align: center;
      margin-bottom: 150px;
      width: 100%;
    }
    &__ttl {
      font-size: 38px;
      span {
        font-size: 13px;
        margin-bottom: 10px;
      }
    }
    &__lead {
      font-size: 18px;
      margin-top: 20px;
    }
    &__slider {
      margin-top: 30px;
      margin-left: -17%;
      width: 134%;
      height: 300px;
      &__item {
        align-items: center;
        overflow: hidden;
        width: 280px;
        height: 280px;
      }
      &__txtBox {
        padding: 0 0 0 8%;
        width: 55%;
      }
      &__imgBox {
        padding: 12px 7% 23px 7%;
        width: 45%;
      }
      &__ttl {
        font-size: 1.1em;
      }
      &__txt, &__price {
        font-size: .8em;
        margin-top: 6.2%;
      }
    }
    &__linkList {
      &__item {
        font-size: .8em;
        margin-top: 15%;
        & + & {
          margin-top: 10%;
        }
      }
    }
    &__btm {
      display: block;
      position: absolute;
      bottom: 20px;
      left: 30px;
      width: calc(100% - 60px);
    }
    &__btmTxt {
      font-size: 14px;
    }
    &__copyright {
      font-size: 10px;
      text-align: center;
      margin-top: 30px;
    }
  }
}
