@charset "UTF-8";
@import "~normalize.css/normalize.css";
@import "~magnific-popup/dist/magnific-popup.css";
@import '~slick-carousel/slick/slick.css';
/*============================================================================================================
	base
============================================================================================================*/
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Noto+Serif+JP:wght@400;500;600&display=swap");
body {
  color: #000;
  -webkit-text-size-adjust: 100%;
  font-weight: normal;
  line-height: 1.8;
  letter-spacing: normal;
}

div, h1, h2, h3, h4, h5, h6, p, ul, ol, li, dl, dt, dd, th, td, section, article, header, footer, main, nav, span, a {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.5;
  font-weight: normal;
  margin: 0;
  word-wrap: break-word;
}

p, li, td, th, dt, dd {
  font-size: 18px;
  margin: 0;
  word-wrap: break-word;
}
@media screen and (max-width: 767px) {
  p, li, td, th, dt, dd {
    font-size: 14px;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: top;
}

ul, ol, dl {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

.cf:after {
  content: "";
  display: block;
  clear: both;
}

@media screen and (min-width: 768px) {
  .sp {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}
/*------------------------------------------
	font
------------------------------------------*/
body, .f-gothic {
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, sans-serif;
}

.f-serif {
  font-family: "Noto Serif JP", "游明朝体", YuMincho, "游明朝", "Yu Mincho", "Hiragino Mincho ProN", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

.noto-sans {
  font-family: "Noto Sans JP", sans-serif;
}

.noto-serif {
  font-family: "Noto Serif JP", serif;
}

/*------------------------------------------
	frame
------------------------------------------*/
body {
  position: relative;
  min-width: initial;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  body {
    min-width: 1200px;
  }
}

/*------------------------------------------
	hover
------------------------------------------*/
@media screen and (min-width: 768px) {
  a {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  a:hover {
    color: #E6E6E6;
  }
  a:active {
    color: #393939;
  }
  a.is_btn_hover:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  a.is_btn_hover:active {
    background: #000;
    border: solid 1px rgba(206, 206, 206, 0.4);
    color: #fff;
  }

  div input[type=submit] {
    background: #0F1113;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  div input[type=submit]:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  div input[type=submit]:active {
    background: #000;
    border: solid 1px rgba(206, 206, 206, 0.4);
    color: #fff;
  }
}
/* imgFitBox imgLinkBox */
.imgFitBox {
  overflow: hidden;
}
.imgFitBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover; object-position: center;";
}
.imgFitBox.contain {
  /* fit = contain */
}
.imgFitBox.contain img {
  object-fit: contain;
  font-family: "object-fit: contain; object-position: center;";
}

.imgLinkBox .imgLink-target {
  overflow: hidden;
}
.imgLinkBox .imgLink-target img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover; object-position: center;";
  transition: all 0.5s ease;
  transform: scale(1);
}
.imgLinkBox:hover {
  opacity: 1;
}
.imgLinkBox:hover .imgLink-target {
  overflow: hidden;
}
.imgLinkBox:hover .imgLink-target img {
  transition: all 0.5s ease;
  transform: scale(1.1);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  max-width: 1440px;
  padding-top: 47px;
  width: 100%;
  z-index: 10;
}
.header__left {
  display: flex;
  align-items: flex-end;
}
.header__right {
  display: flex;
  align-items: flex-end;
}
.header__ventilation {
  color: #fff;
  display: none;
  font-size: 18px;
  margin-right: 20px;
}
.header__ventilation.is_show {
  display: block;
}
.header__lettering {
  color: #fff;
  display: none;
  font-size: 18px;
  margin-right: 20px;
}
.header__lettering.is_show {
  display: block;
}
.header__others {
  color: #fff;
  display: none;
  font-size: 18px;
  margin-right: 20px;
}
.header__others.is_show {
  display: block;
}
.header__logo {
  width: 261px;
  height: 47px;
}
.header__lead {
  color: #fff;
  font-size: 14px;
  margin-left: 8px;
}
.header__price {
  border: solid 1px #fff;
  color: #fff;
  font-size: 24px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.05em;
  width: 200px;
  height: 50px;
}
@media screen and (max-width: 767px) {
  .header {
    background: #000;
    height: 48px;
    padding-top: 0;
    padding-left: 20px;
  }
  .header.is_atop {
    background: transparent;
  }
  .header__right {
    align-items: center;
  }
  .header__ventilation {
    font-size: 10px;
    margin-right: 20px;
  }
  .header__lettering {
    font-size: 10px;
    margin-right: 20px;
  }
  .header__others {
    font-size: 10px;
    margin-right: 20px;
  }
  .header__logo {
    width: 89px;
    height: 14px;
  }
  .header__logo a {
    display: block;
    width: 100%;
    height: 100%;
  }
  .header__logo a img {
    vertical-align: top;
  }
  .header__lead {
    color: #fff;
    font-size: 14px;
    margin-left: 8px;
  }
  .header__price {
    border: 0;
    font-size: 14px;
    width: auto;
    height: auto;
    margin-top: 0;
    margin-right: 20px;
  }
}

.footer {
  background: #000;
  position: relative;
  width: 100%;
  height: 80px;
  z-index: 10;
}
.footer__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  max-width: 1470px;
  padding: 0 15px;
  width: 100%;
  height: 80px;
}
.footer__list__item {
  color: #8B8B8B;
  font-size: 18px;
}
.footer__list__item.is_font_small {
  font-size: 14px;
}
.footer__list__item a {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.footer__list__item img {
  margin-right: 10px;
  width: auto;
}
.footer__ico__return {
  height: 23px;
}
.footer__ico__save {
  height: 30px;
}
.footer__ico__contact {
  height: 30px;
}
@media screen and (max-width: 767px) {
  .footer {
    height: 40px;
  }
  .footer__list {
    padding: 0;
    height: 40px;
  }
  .footer__list__item {
    font-size: 14px;
    width: 33.3%;
  }
  .footer__list__item a {
    justify-content: center;
  }
  .footer__list__item img {
    margin-right: 5px;
  }
  .footer__ico__return {
    height: 18px;
  }
  .footer__ico__save {
    height: 18px;
  }
  .footer__ico__contact {
    height: 18px;
  }
}

.wrapper {
  overflow: hidden;
  position: relative;
}

.article {
  background: url(../img/bg/bg01.jpg) no-repeat center center;
  background-size: cover;
  display: flex;
  padding: 150px 0 60px;
  position: relative;
  width: 100%;
  height: calc(100vh - 80px);
  min-height: 940px;
}
.article__box {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  height: 100%;
  max-height: 768px;
}
.article__firstAccess {
  background: rgba(0, 0, 0, 0.84);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% + 80px);
  z-index: 10000;
}
.article__center {
  position: relative;
  height: 100%;
}
.article__center.is_center {
  margin: auto;
}
.article__center.is_option .article__center__lower {
  height: calc(100% - 70px);
}
.article__center.is_option .article__center__btn {
  display: block;
}
.article__center__lower {
  width: auto;
  height: 100%;
}
.article__center__upper {
  position: absolute;
  top: 5px;
  left: 30px;
  width: 352px;
  height: 670px;
  z-index: 10001;
}
.article__center__upper span {
  background: rgba(255, 255, 255, 0.11);
  border: solid 1px #fff;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  width: 131px;
  height: 131px;
  transition: 0.3s;
}
.article__center__upper span:hover {
  background: rgba(255, 255, 255, 0.4);
}
.article__center__upper span:nth-child(1) {
  left: 0;
  top: 0;
}
.article__center__upper span:nth-child(2) {
  right: 0;
  top: 162px;
}
.article__center__upper span:nth-child(3) {
  left: 50px;
  bottom: 210px;
}
.article__center__upper span:nth-child(4) {
  right: 30px;
  bottom: 0;
}
.article__center__box {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.article__center__box .ico_pointer01 {
  background: url(../img/ico/ico_pointer01.png) no-repeat;
  background-size: 100% 100%;
  content: "";
  position: absolute;
  right: -15px;
  top: 240px;
  width: 70px;
  height: 87px;
}
.article__center__ttl {
  color: #fff;
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  width: 700px;
  z-index: 10001;
}
.article__center__btn {
  margin-top: 70px;
}
.article__center__btn a {
  background: #0F1113;
  border: solid 1px #CECECE;
  color: #CECECE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin: 30px auto 0 0;
  width: 194px;
  height: 40px;
}
.article__left {
  background: #000;
  color: #fff;
  text-align: center;
  position: relative;
  width: 250px;
  z-index: 1;
}
.article__right {
  position: relative;
  max-width: 477px;
  width: 100%;
}
.article__right__txt {
  color: #fff;
  font-size: 14px;
  position: absolute;
  right: 0;
  top: -35px;
}
.article__right__txt img {
  margin-left: 10px;
  vertical-align: middle;
  width: 18px;
  height: 18px;
}
@media screen and (max-width: 767px) {
  .article {
    background: url(../img/bg/bg01_sp.jpg) no-repeat center center;
    background-size: cover;
    display: block;
    padding: 0;
    position: relative;
    height: calc(100vh - 88px);
    min-height: initial;
  }
  .article__box {
    display: block;
    max-height: initial;
    position: relative;
  }
  .article__box .ico_pointer01 {
    right: -4%;
    top: 23vw;
    width: 35px;
    height: 43px;
  }
  .article__firstAccess {
    background: rgba(0, 0, 0, 0.84);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 10000;
  }
  .article__center {
    position: relative;
    height: calc(100% - 213px);
    margin-left: auto;
    width: 66.6%;
  }
  .article__center.is_center {
    margin-left: auto;
    margin-right: 0;
  }
  .article__center.is_option .article__center__lower {
    height: auto;
  }
  .article__center.is_option .article__center__btn {
    display: block;
  }
  .article__center__lower {
    position: absolute;
    top: 50px;
    right: 16.4%;
    width: 55%;
    height: auto;
  }
  .article__center__upper {
    top: 50px;
    right: 16.4%;
    left: auto;
    width: 55%;
    height: 70vw;
  }
  .article__center__upper span {
    width: 40%;
    height: 15vw;
  }
  .article__center__upper span:nth-child(1) {
    left: 0;
    top: 0;
  }
  .article__center__upper span:nth-child(2) {
    right: 0;
    top: 15vw;
  }
  .article__center__upper span:nth-child(3) {
    left: 8%;
    bottom: 24vw;
  }
  .article__center__upper span:nth-child(4) {
    right: 8%;
    bottom: 0;
  }
  .article__center__ttl {
    font-size: 18px;
    position: fixed;
    top: auto;
    bottom: 100px;
    left: 10%;
    transform: translateY(0);
    width: 80%;
  }
  .article__center__btn {
    position: fixed;
    left: 0;
    bottom: 40px;
    margin-top: 0;
    width: 100%;
  }
  .article__center__btn a {
    font-size: 15px;
    margin: 0;
    width: 100%;
    height: 50px;
  }
  .article__left {
    background: #1D1D1D;
    display: flex;
    margin-top: 48px;
    position: static;
    width: 100%;
  }
  .article__right {
    position: static;
    max-width: 477px;
    width: 100%;
  }
  .article__right__txt {
    right: auto;
    left: 10px;
    top: 80px;
  }
  .article__right__txt img {
    margin-left: 10px;
  }
}

.suit__colorBox {
  background: #000;
}
.suit__colorBox.is_coloring.is_select {
  position: relative;
}
.suit__colorBox.is_coloring.is_select::before {
  position: absolute;
  content: "";
  top: 30px;
  right: -17px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 0 30px 17px;
  border-color: transparent transparent transparent #000;
}
.suit__colorBox.is_select {
  background: #000;
}
.suit__colorBox.is_select .suit__colorBox__content {
  display: block;
  padding: 1px 15px 15px;
}
.suit__colorBox.is_select .suit__colorBox__content.is_hide {
  display: none;
}
.suit__colorBox.is_select .suit__colorBox__content2 {
  display: block;
}
.suit__colorBox.is_select .suit__colorBox__ttl {
  padding-top: 30px;
  height: 70px;
}
.suit__colorBox.is_select .suit__colorBox__ttl a {
  color: #fff;
}
.suit__colorBox__ttl {
  font-size: 24px;
  font-weight: normal;
  letter-spacing: 0.05em;
  height: 80px;
}
.suit__colorBox__ttl a {
  color: #707070;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.suit__colorBox__ttl a:hover {
  color: #343030;
}
.suit__colorBox__ttl a:active {
  color: #fff;
}
.suit__colorBox__content {
  display: none;
}
.suit__colorBox__content2 {
  background: #000;
  display: none;
  text-align: left;
  padding: 30px 15px;
  position: absolute;
  top: 0;
  left: 250px;
  width: 250px;
  height: 100%;
}
.suit__colorBox__caution {
  color: #FF0000;
  font-size: 12px;
}
.suit__colorBox__radio {
  margin-top: 20px;
  margin-left: 10px;
}
.suit__colorBox__radio label {
  color: #CECECE;
  cursor: pointer;
  display: block;
  font-size: 16px;
  margin-top: 10px;
}
.suit__colorBox__radio input {
  margin-right: 14px;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}
.suit__colorBox__color {
  text-align: center;
  margin-top: 50px;
}
.suit__colorBox__colorSet {
  border: solid 1px #707070;
  border-radius: 50%;
  margin: auto;
  width: 60px;
  height: 60px;
}
.suit__colorBox__colorSet a {
  border-radius: 50%;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.suit__colorBox__colorSet a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.suit__colorBox__colorSet a:hover::before {
  background: rgba(255, 255, 255, 0.2);
  opacity: 1;
}
.suit__colorBox__colorSet a:active::before {
  background: rgba(0, 0, 0, 0.3);
  opacity: 1;
}
.suit__colorBox__colorTxt {
  color: #BFBFBF;
  font-size: 16px;
  margin-top: 15px;
}
.suit__colorBox__ttl2 {
  color: #CECECE;
  font-size: 16px;
  margin-top: 50px;
}
.suit__colorBox__ttl3 {
  color: #CECECE;
  font-size: 36px;
}
.suit__colorBox__txt {
  color: #CECECE;
  font-size: 12px;
  margin-top: 10px;
}
.suit__colorBox__style, .suit__colorBox__style2 {
  font-size: 14px;
  margin-top: 10px;
}
.suit__colorBox__selectBox {
  margin-top: 20px;
  position: relative;
}
.suit__colorBox__name {
  background: #fff;
  color: #000;
  font-size: 12px;
  padding: 0 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 23px;
}
.suit__colorBox__btn {
  border: solid 1px #CECECE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin: 30px auto 0;
  width: 200px;
  height: 46px;
  position: relative;
  z-index: 1;
}
.suit__colorBox__btn2 {
  color: #707070;
  display: block;
  font-size: 24px;
  margin-top: 30px;
}
.suit__colorBox__options {
  margin-top: 20px;
}
.suit__colorBox__options__item.is_select a {
  color: #fff;
}
.suit__colorBox__options__item a {
  color: #707070;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 50px;
}
.suit__colorBox__options__item a:hover {
  color: #343030;
}
.suit__colorBox__options__item a:active {
  color: #fff;
}
@media screen and (max-width: 767px) {
  .suit__colorBox {
    width: 33.3%;
    height: 40px;
  }
  .suit__colorBox.is_coloring.is_select {
    position: static;
  }
  .suit__colorBox.is_select {
    background: #000;
  }
  .suit__colorBox.is_select .suit__colorBox__content {
    padding: 0;
  }
  .suit__colorBox.is_select .suit__colorBox__content2 {
    display: block;
  }
  .suit__colorBox.is_select .suit__colorBox__ttl {
    padding-top: 0;
    height: 40px;
  }
  .suit__colorBox__ttl {
    font-size: 16px;
    letter-spacing: normal;
    height: 40px;
  }
  .suit__colorBox__ttl a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .suit__colorBox__content {
    background: #000;
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
  }
  .suit__colorBox__content.is_option {
    top: 40px;
    bottom: auto;
  }
  .suit__colorBox__content.is_option .suit__colorBox__txt {
    background: #000;
    color: #707070;
    position: fixed;
    padding: 5px 0;
    left: 0;
    bottom: 150px;
    width: 100%;
  }
  .suit__colorBox__content.is_option .suit__colorList {
    background: #000;
    position: fixed;
    left: 0;
    bottom: 90px;
  }
  .suit__colorBox__content2 {
    background: transparent;
    padding: 15px;
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    height: calc(100% - 40px);
  }
  .suit__colorBox__caution {
    font-size: 19px;
  }
  .suit__colorBox__radio {
    width: 60%;
    margin-top: 20px;
    margin-left: 10px;
  }
  .suit__colorBox__radio label {
    font-size: 14px;
    margin-top: 20px;
  }
  .suit__colorBox__radio label span {
    font-size: 12px;
  }
  .suit__colorBox__radio input {
    margin-right: 10px;
  }
  .suit__colorBox__box {
    background: #000;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 65px;
    width: 100%;
    height: 40px;
  }
  .suit__colorBox__color {
    display: flex;
    align-items: center;
    margin-top: 0;
    text-align: left;
  }
  .suit__colorBox__color + .suit__colorBox__color {
    margin-left: 28px;
  }
  .suit__colorBox__colorSet {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
  .suit__colorBox__colorTxt {
    color: #fff;
    font-size: 14px;
    margin-top: 0;
  }
  .suit__colorBox__ttl2 {
    font-size: 24px;
    margin-top: 0;
  }
  .suit__colorBox__ttl2.is_small_font {
    font-size: 20px;
  }
  .suit__colorBox__ttl3 {
    color: #CECECE;
    font-size: 36px;
  }
  .suit__colorBox__ttl4 {
    font-size: 14px;
  }
  .suit__colorBox__ttl4 img {
    width: 18px;
    margin-left: 10px;
    height: auto;
  }
  .suit__colorBox__txt {
    font-size: 14px;
    margin-top: 20px;
  }
  .suit__colorBox__slideArrowSp {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .suit__colorBox__slideArrowSp__left img, .suit__colorBox__slideArrowSp__right img {
    width: 10px;
    height: auto;
  }
  .suit__colorBox__slideArrowSp__left {
    margin-right: 16px;
  }
  .suit__colorBox__slideArrowSp__right {
    margin-left: 16px;
  }
  .suit__colorBox__style, .suit__colorBox__style2 {
    font-size: 14px;
    margin-top: 0;
  }
  .suit__colorBox__selectBox {
    margin-top: 0;
    position: absolute;
    left: 30px;
    bottom: 190px;
    width: 80px;
    height: 80px;
    z-index: 1;
  }
  .suit__colorBox__name {
    width: 100%;
  }
  .suit__colorBox__btn {
    border: solid 1px #CECECE;
    font-size: 15px;
    margin: 0;
    width: 100%;
    height: 50px;
  }
  .suit__colorBox__btn2 {
    color: #707070;
    display: block;
    font-size: 24px;
    margin-top: 30px;
  }
  .suit__colorBox__options {
    display: flex;
    margin-top: 0;
  }
  .suit__colorBox__options__item {
    width: 33.3%;
  }
  .suit__colorBox__options__item.is_select a {
    background: #fff;
    color: #1D1D1D;
  }
  .suit__colorBox__options__item a {
    font-size: 14px;
    height: 27px;
  }
}

.suit__colorList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-left: -2px;
}
.suit__colorList.is_lettering {
  display: none;
}
.suit__colorList.is_lettering.is_show {
  display: flex;
}
.suit__colorList__item {
  margin-left: 2px;
  margin-bottom: 2px;
  width: 35px;
  height: 35px;
}
.suit__colorList__item a {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}
.suit__colorList__item a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.suit__colorList__item a:hover::before {
  background: rgba(255, 255, 255, 0.2);
  opacity: 1;
}
.suit__colorList__item a:active::before {
  background: rgba(0, 0, 0, 0.3);
  opacity: 1;
}
@media screen and (max-width: 767px) {
  .suit__colorList {
    overflow-x: hidden;
    flex-wrap: nowrap;
    margin-top: 10px;
    margin-left: 0;
    width: 100%;
  }
  .suit__colorList__item {
    margin: 0 10px;
    margin-bottom: 8px;
    width: 40px !important;
    height: 40px;
  }
}

.suit__top {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 421px;
  width: 100%;
}
.suit__top__imgBox {
  height: 117px;
}
.suit__top__item {
  height: 117px;
}
.suit__top__item a {
  display: flex;
  align-items: center;
  height: 100%;
}
.suit__top__item img {
  width: auto;
  height: 100%;
}
.suit__top__txt {
  color: #fff;
  font-size: 12px;
  margin-left: 10px;
}
@media screen and (max-width: 767px) {
  .suit__top {
    display: block;
    position: absolute;
    left: 30px;
    top: 160px;
    max-width: 22px;
  }
  .suit__top__imgBox {
    display: block;
    width: 16px;
    height: 32px;
  }
  .suit__top__item {
    text-align: center;
    width: 60px;
    height: 32px;
    margin: auto;
  }
  .suit__top__item a {
    width: 100%;
  }
  .suit__top__item + .suit__top__item {
    margin-top: 30px;
  }
}

.suit__middle {
  display: flex;
  justify-content: space-between;
  margin: 70px auto 0;
}
.suit__middle__item {
  height: 400px;
}
.suit__middle__item img {
  width: auto;
  height: 100%;
}

.suit__bottom {
  margin: 60px auto 0;
}
.suit__bottom__item a {
  background: #0F1113;
  border: solid 1px #CECECE;
  color: #CECECE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin: auto;
  width: 320px;
  height: 46px;
}
.suit__bottom__item a span {
  margin-right: 3px;
}
.suit__bottom__item + .suit__bottom__item {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .suit__bottom {
    position: absolute;
    top: 40px;
    left: 0;
    margin: 0;
    display: flex;
    width: 100%;
  }
  .suit__bottom__item {
    width: 50%;
  }
  .suit__bottom__item a {
    background: #0F1113;
    border: solid 1px #CECECE;
    font-size: 14px;
    width: 100%;
    height: 31px;
  }
  .suit__bottom__item + .suit__bottom__item {
    margin-top: 0;
  }
}

.popupBox__wrap {
  display: none;
  position: relative;
}
.popupBox__wrap::before {
  background: transparent;
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}
.popupBox__item {
  background: #DADDE3;
  border: solid 1px #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.41);
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  max-width: 700px;
  padding: 55px 15px 60px;
  text-align: center;
  width: 100%;
  z-index: 10000;
}
.popupBox__ttl {
  font-size: 24px;
}
.popupBox__txt {
  font-size: 16px;
  margin-top: 20px;
}
.popupBox__eng {
  font-size: 20px;
  margin-top: 40px;
  line-height: 1.4;
}
.popupBox__eng span {
  font-size: 14px;
  display: block;
  margin-top: 5px;
}
.popupBox__caution {
  color: #FF0000;
  font-size: 24px;
  margin-top: 30px;
}
.popupBox__caution2 {
  color: #970005;
  font-size: 14px;
  margin-top: 8px;
}
.popupBox__btnBox {
  display: flex;
  justify-content: space-between;
  margin: 60px auto 0;
  max-width: 240px;
  width: 100%;
}
.popupBox__btnBox.is_center {
  justify-content: center;
}
.popupBox__btnBox.is_wide {
  max-width: 370px;
}
.popupBox__btnBox.is_wide2 {
  max-width: 500px;
}
.popupBox__btnBox.is_wide .popupBox__btn {
  width: 370px;
}
.popupBox__btnBox.is_wide2 .popupBox__btn.is_wide2 {
  width: 356px;
}
.popupBox__btn {
  background: #0F1113;
  color: #CECECE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width: 100px;
  height: 40px;
}
.popupBox__btn.is_btnwide {
  width: 356px;
}
.popupBox__sendComplete {
  background: #fff;
}
.popupBox__sendComplete .popupBox__eng {
  margin-top: 10px;
}
.popupBox__sizeBox .popupBox__item {
  background: #fff;
  max-width: 1440px;
  padding: 70px 20px 70px 60px;
}
@media screen and (max-width: 767px) {
  .popupBox__wrap {
    background: rgba(9, 9, 9, 0.8);
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
  }
  .popupBox__item {
    left: 30px;
    right: auto;
    margin: auto;
    padding: 35px 30px 25px;
    width: calc(100% - 60px);
  }
  .popupBox__ttl {
    font-size: 18px;
  }
  .popupBox__txt {
    font-size: 14px;
  }
  .popupBox__txt.is_sp_big {
    font-size: 18px;
    margin-top: 0;
  }
  .popupBox__eng {
    font-size: 14px;
    margin-top: 15px;
  }
  .popupBox__caution {
    color: #FF0000;
    font-size: 24px;
    margin-top: 30px;
  }
  .popupBox__caution2 {
    font-size: 14px;
    margin-top: 8px;
  }
  .popupBox__btnWrap {
    display: flex;
    flex-direction: column-reverse;
  }
  .popupBox__btnBox {
    margin: 40px auto 0;
    max-width: 220px;
  }
  .popupBox__btnBox.is_wide {
    margin-top: 20px;
    max-width: 220px;
  }
  .popupBox__btnBox.is_wide2 {
    display: flex;
    flex-direction: column-reverse;
    max-width: 220px;
  }
  .popupBox__btnBox.is_wide .popupBox__btn {
    height: 60px;
    width: 220px;
  }
  .popupBox__btnBox.is_wide2 .popupBox__btn {
    margin: 20px auto 0;
  }
  .popupBox__btnBox.is_wide2 .popupBox__btn.is_wide2 {
    margin-top: 0;
    width: 220px;
    height: 60px;
  }
  .popupBox__btn {
    font-size: 15px;
  }
  .popupBox__btn.cancel__btn {
    background: #707070;
  }
  .popupBox__btn.is_btnwide {
    width: 356px;
  }
  .popupBox__sendComplete .popupBox__eng {
    margin-top: 10px;
  }
  .popupBox__sizeBox .popupBox__item {
    padding: 30px 20px;
    top: 0;
    left: 0;
    transform: translateY(0);
    overflow-y: scroll;
    width: 100%;
    height: 100vh;
  }
}

.sizeBox {
  background: #fff;
  text-align: left;
  position: fixed;
  left: 0;
  top: 0;
  padding: 60px;
  margin: 0 auto;
  overflow-y: scroll;
  width: 100%;
  height: 100vh;
  z-index: 10000;
}
.sizeBox ::-webkit-scrollbar {
  width: 10px;
}
.sizeBox ::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #fff 0%, #fff 40%, #BFBFBF 40%, #BFBFBF 60%, #fff 60%, #fff 100%);
}
.sizeBox ::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 5px;
  width: 10px;
}
.sizeBox__close {
  background: url(../img/ico/ico_close01.svg) no-repeat;
  background-size: 50px 50px;
  position: absolute;
  top: 25px;
  right: 40px;
  width: 50px;
  height: 50px;
}
.sizeBox__scrollBox {
  overflow-y: visible;
  height: auto;
}
.sizeBox__ttl {
  border-bottom: solid 1px #202020;
  font-size: 18px;
  text-align: left;
  padding-bottom: 10px;
}
.sizeBox__ttl2 {
  font-size: 24px;
  text-align: left;
  margin-top: 50px;
}
.sizeBox__ttl2 span {
  font-size: 16px;
  margin-left: 10px;
}
.sizeBox__txt {
  font-size: 14px;
  text-align: left;
  margin: 55px 0;
}
.sizeBox__table {
  border-top: solid 1px #707070;
  border-left: solid 1px #707070;
  margin-top: 10px;
  max-width: 100%;
  width: auto;
}
.sizeBox__table tr:nth-child(n+3) th {
  background: #282B2E;
}
.sizeBox__table th, .sizeBox__table td {
  border-right: solid 1px #707070;
  border-bottom: solid 1px #707070;
  text-align: center;
  padding: 4px;
  vertical-align: middle;
}
.sizeBox__table th {
  background: #0D0F10;
  color: #fff;
  font-size: 14px;
}
.sizeBox__table th.is_big {
  font-size: 16px;
}
.sizeBox__table td {
  font-size: 14px;
  min-width: 60px;
}
.sizeBox__table td:nth-child(2n) {
  background: #D0D3DA;
}
.sizeBox__table .is_font_big th {
  font-size: 16px;
}
.sizeBox__table .bg_white td {
  background: #fff;
}
.sizeBox__list {
  margin-top: 28px;
}
.sizeBox__list__item {
  font-size: 14px;
  text-align: left;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .sizeBox {
    text-align: left;
    position: relative;
    padding: 30px 10px 30px 20px;
  }
  .sizeBox__close {
    background-size: 100% 100%;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
  }
  .sizeBox__scrollBox {
    overflow-x: hidden;
  }
  .sizeBox__ttl {
    border-bottom: solid 1px #202020;
    font-size: 18px;
    text-align: left;
    padding-bottom: 10px;
  }
  .sizeBox__ttl2 {
    font-size: 24px;
    text-align: left;
    margin-top: 50px;
  }
  .sizeBox__ttl2 span {
    font-size: 16px;
    margin-left: 10px;
  }
  .sizeBox__txt {
    margin: 30px 0;
  }
  .sizeBox__table {
    border-top: solid 1px #707070;
    border-left: solid 1px #707070;
    margin-top: 10px;
    max-width: 100%;
    width: auto;
  }
  .sizeBox__table__wrap {
    overflow-x: scroll;
    padding-bottom: 10px;
    width: 100%;
  }
  .sizeBox__table__wrap::-webkit-scrollbar {
    width: 100%;
    height: 10px;
  }
  .sizeBox__table__wrap::-webkit-scrollbar-track {
    background: linear-gradient(0deg, #fff 0%, #fff 40%, #BFBFBF 40%, #BFBFBF 60%, #fff 60%, #fff 100%);
  }
  .sizeBox__table__wrap::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 5px;
    height: 10px;
  }
  .sizeBox__table + .sizeBox__table {
    margin-top: 30px;
  }
  .sizeBox__table tr:nth-child(n+3) th {
    background: #282B2E;
    min-width: 100px;
  }
  .sizeBox__table th, .sizeBox__table td {
    border-right: solid 1px #707070;
    border-bottom: solid 1px #707070;
    text-align: center;
    padding: 4px;
    vertical-align: middle;
  }
  .sizeBox__table th {
    background: #0D0F10;
    color: #fff;
    font-size: 14px;
  }
  .sizeBox__table th.is_big {
    font-size: 16px;
  }
  .sizeBox__table td {
    font-size: 16px;
    min-width: 58px;
  }
  .sizeBox__table .is_font_big th {
    font-size: 16px;
  }
  .sizeBox__table .bg_white td {
    background: #fff;
  }
  .sizeBox__list {
    margin-top: 28px;
  }
  .sizeBox__list__item {
    font-size: 14px;
    text-align: left;
    margin-bottom: 30px;
  }
}

.hugeModal {
  background: #090909;
  color: #BFBFBF;
  margin: auto;
  max-width: 1440px;
  position: relative;
  padding: 60px;
  width: 100%;
  height: 900px;
}
.hugeModal__imgBox {
  margin: 50px auto 0;
  max-width: 1230px;
  overflow-y: scroll;
  padding-right: 40px;
  width: 100%;
  height: 580px;
}
.hugeModal__imgBox__ttl {
  font-size: 16px;
  text-align: center;
}
.hugeModal__ttl {
  font-size: 28px;
  font-family: "Arial", "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, sans-serif;
  margin: auto;
  max-width: 1200px;
  width: 100%;
}
.hugeModal__ttl span {
  font-size: 16px;
  margin-left: 20px;
}
.hugeModal__ttl.is_center {
  text-align: center;
}
.hugeModal__ttl img {
  margin-left: -20px;
  margin-right: 24px;
  vertical-align: middle;
  width: 73px;
  height: 73px;
}
.hugeModal__txt {
  font-size: 16px;
  text-align: center;
  margin-top: 30px;
}
.hugeModal__smallTxt {
  font-size: 10px;
  text-align: left;
  margin: 5px auto 0;
  max-width: 1200px;
  width: 100%;
}
.hugeModal__saveImgList {
  display: flex;
  justify-content: space-between;
  margin: 70px auto 0;
  max-width: 658px;
  width: 100%;
}
.hugeModal__saveImgList__item {
  height: 330px;
}
.hugeModal__inputBox {
  margin: 60px auto 0;
}
.hugeModal__inputBox input {
  background: #CECECE;
  border: solid 1px #707070;
  box-sizing: border-box;
  display: block;
  font-size: 16px;
  margin: auto;
  max-width: 600px;
  padding-left: 20px;
  width: 100%;
  height: 52px;
}
.hugeModal__inputBox__error {
  color: #FF0000;
  display: none;
  font-size: 14px;
  text-align: center;
  margin-top: 3px;
}
.hugeModal__inputBox__error.is_show {
  display: block;
}
.hugeModal__agreeBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.hugeModal__agreeBox label {
  cursor: pointer;
}
.hugeModal__agreeBox input[type=checkbox] {
  appearance: none;
  background: #FFFFFF;
  border: solid 1px #707070;
  margin-right: 12px;
  position: relative;
  vertical-align: middle;
  width: 30px;
  height: 30px;
}
.hugeModal__agreeBox input[type=checkbox]:checked::before {
  background: url(../img/ico/ico_check01.svg);
  background-size: 15px 10px;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: inline-block;
  width: 15px;
  height: 10px;
}
.hugeModal__agreeBox a {
  text-decoration: underline;
}
.hugeModal__agreeBox__error {
  color: #FF0000;
  display: none;
  font-size: 14px;
  text-align: center;
  margin-top: 3px;
}
.hugeModal__agreeBox__error.is_show {
  display: block;
}
.hugeModal__submitBox {
  margin-top: 40px;
}
.hugeModal__submitBox input[type=submit] {
  border: solid 1px #CECECE;
  color: #CECECE;
  cursor: pointer;
  font-size: 16px;
  display: block;
  margin: auto;
  width: 200px;
  height: 40px;
}
.hugeModal__btnBox {
  display: flex;
  justify-content: center;
  margin: 90px auto 0;
  max-width: 840px;
  width: 100%;
}
.hugeModal__hugeBtn {
  border: solid 1px #707070;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
  font-size: 16px;
  max-width: 400px;
  width: 100%;
  height: 40px;
}
.hugeModal__imgList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.hugeModal__imgList.is_huge .hugeModal__imgList__item {
  max-width: 380px;
  width: 32%;
}
.hugeModal__imgList.is_huge .hugeModal__imgList__item:nth-child(3n) {
  margin-right: 0;
}
.hugeModal__imgList__item {
  border: solid 1px #000;
  margin-bottom: 20px;
  margin-right: 20px;
  max-width: 180px;
  width: 16%;
}
.hugeModal__imgList__item a {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}
.hugeModal__imgList__item a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.hugeModal__imgList__item a:hover::before {
  background: rgba(255, 255, 255, 0.2);
  opacity: 1;
}
.hugeModal__imgList__item a:active::before {
  background: rgba(0, 0, 0, 0.3);
  opacity: 1;
}
.hugeModal__imgList__item.is_current {
  background-color: #B3B3B3;
}
.hugeModal__imgList__item.is_current img {
  filter: brightness(200%);
}
.hugeModal__imgList__item:nth-child(6n) {
  margin-right: 0;
}
.hugeModal__return {
  margin: auto;
  max-width: 1180px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 55px;
}
.hugeModal__return img {
  margin-right: 10px;
  width: 24px;
  height: 23px;
}
.hugeModal__saveList {
  display: flex;
  justify-content: space-between;
  margin: 50px auto 0;
  max-width: 1200px;
  width: 100%;
  height: 700px;
}
.hugeModal__saveList__item {
  background: #000;
  max-width: 360px;
  width: 30%;
}
.hugeModal__saveList__newSave {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}
.hugeModal__saveList__newSave::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.hugeModal__saveList__newSave:hover::before {
  background: rgba(255, 255, 255, 0.2);
  opacity: 1;
}
.hugeModal__saveList__newSave:active::before {
  background: rgba(0, 0, 0, 0.3);
  opacity: 1;
}
.hugeModal__saveList__imgBox {
  padding: 50px 58px 30px;
}
.hugeModal__saveList__imgBox img {
  width: 100%;
  height: auto;
}
.hugeModal__saveList__newBox {
  text-align: center;
}
.hugeModal__saveList__newBox img {
  width: 70px;
  height: 70px;
}
.hugeModal__saveList__ttl {
  color: #8B8B8B;
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
}
.hugeModal__saveList__ttl span {
  display: block;
}
.hugeModal__saveList__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 20px 20px;
}
.hugeModal__saveList__replace {
  color: #8B8B8B;
  display: flex;
  font-size: 14px;
}
.hugeModal__saveList__replace img {
  margin-right: 10px;
  width: 24px;
  height: 23px;
}
.hugeModal__saveList__delete img {
  vertical-align: top;
}
.hugeModal__helpList {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 64px;
}
.hugeModal__helpList__ttl {
  border-bottom: solid 1px #707070;
  font-size: 24px;
}
.hugeModal__helpList__item {
  font-size: 14px;
  width: 45%;
}
@media screen and (max-width: 767px) {
  .hugeModal {
    background: #000;
    padding: 30px 25px;
    height: 100vh;
  }
  .hugeModal__imgBox {
    margin: 20px auto 0;
    overflow-y: visible;
    padding-right: 0;
    padding-bottom: 100px;
    height: auto;
  }
  .hugeModal__ttl {
    font-size: 20px;
  }
  .hugeModal__ttl span {
    font-size: 14px;
    display: block;
    margin-left: 0;
    margin-top: 10px;
  }
  .hugeModal__ttl.is_center {
    text-align: center;
  }
  .hugeModal__ttl img {
    margin-left: 0;
    margin-right: 12px;
    width: 36px;
    height: 36px;
  }
  .hugeModal__txt {
    font-size: 14px;
    margin-top: 20px;
  }
  .hugeModal__saveImgList {
    margin: 46px auto 0;
    max-width: 280px;
  }
  .hugeModal__saveImgList__item {
    height: 162px;
  }
  .hugeModal__inputBox {
    margin: 40px auto 0;
  }
  .hugeModal__inputBox input {
    font-size: 16px;
    height: 40px;
  }
  .hugeModal__agreeBox {
    font-size: 14px;
    margin: 30px auto 0;
    max-width: 263px;
    width: 100%;
  }
  .hugeModal__agreeBox label {
    display: flex;
  }
  .hugeModal__agreeBox input[type=checkbox] {
    vertical-align: middle;
    width: 20px;
    height: 20px;
  }
  .hugeModal__agreeBox__right {
    width: calc(100% - 30px);
  }
  .hugeModal__submitBox {
    margin-top: 30px;
  }
  .hugeModal__submitBox input[type=submit] {
    background: #000;
    border: solid 1px #CECECE;
    color: #CECECE;
    cursor: pointer;
    font-size: 16px;
    display: block;
    margin: auto;
    width: 200px;
    height: 40px;
    transition: 0.3s;
  }
  .hugeModal__submitBox input[type=submit]:hover {
    opacity: 0.7;
  }
  .hugeModal__btnBox {
    display: block;
    margin: 40px auto 0;
  }
  .hugeModal__hugeBtn {
    font-size: 15px;
    max-width: 220px;
    height: 60px;
  }
  .hugeModal__hugeBtn + .hugeModal__hugeBtn {
    margin-top: 20px;
  }
  .hugeModal__btn {
    background: #090909;
    position: fixed;
    left: 0;
    bottom: 40px;
    width: 100%;
  }
  .hugeModal__imgList.is_huge .hugeModal__imgList__item {
    margin-right: 2%;
    margin-left: 0;
    max-width: 380px;
    width: 49%;
  }
  .hugeModal__imgList.is_huge .hugeModal__imgList__item:nth-child(2n) {
    margin-right: 0;
  }
  .hugeModal__imgList__item {
    margin: 0 1.5% 15px;
    max-width: 180px;
    width: 22%;
  }
  .hugeModal__imgList__item:nth-child(6n) {
    margin-right: 1.5%;
  }
  .hugeModal__return {
    background: #090909;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: fixed;
    right: auto;
    bottom: 0;
    height: 40px;
  }
  .hugeModal__return img {
    margin-right: 5px;
    width: 20px;
    height: auto;
  }
  .hugeModal__saveList {
    display: block;
    margin: 20px auto 0;
    height: auto;
  }
  .hugeModal__saveList__item {
    background: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 360px;
    width: 100%;
    min-height: 340px;
  }
  .hugeModal__saveList__item + .hugeModal__saveList__item {
    margin-top: 20px;
  }
  .hugeModal__saveList__newSave {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .hugeModal__saveList__imgBox {
    display: flex;
    justify-content: center;
    padding: 20px 15px;
    width: 100%;
    height: 340px;
  }
  .hugeModal__saveList__imgBox img {
    width: auto;
    height: 100%;
  }
  .hugeModal__saveList__newBox img {
    width: 70px;
    height: 70px;
  }
  .hugeModal__saveList__ttl {
    color: #8B8B8B;
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
  }
  .hugeModal__saveList__ttl span {
    display: block;
  }
  .hugeModal__saveList__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px 20px 20px;
  }
  .hugeModal__saveList__replace {
    align-items: center;
  }
  .hugeModal__saveList__delete img {
    vertical-align: top;
  }
  .hugeModal__helpList {
    display: block;
    margin-top: 20px;
    margin-bottom: 52px;
  }
  .hugeModal__helpList__item {
    width: 100%;
  }
  .hugeModal__helpList__item + .hugeModal__helpList__item {
    margin-top: 25px;
  }
}

@media screen and (max-width: 767px) {
  .mfp-wrap {
    background: #090909;
  }
}

.contactModal {
  background: #fff;
  color: #202020;
  margin: 0 auto;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  padding: 60px;
  width: 100%;
  height: 100vh;
}
.contactModal ::-webkit-scrollbar {
  width: 10px;
}
.contactModal ::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #fff 0%, #fff 40%, #BFBFBF 40%, #BFBFBF 60%, #fff 60%, #fff 100%);
}
.contactModal ::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 5px;
  width: 10px;
}
.contactModal__return {
  display: flex;
  align-items: center;
  position: absolute;
  left: 40px;
  top: 40px;
}
.contactModal__return__ico {
  margin-right: 15px;
  width: 37px;
  height: 36px;
}
.contactModal__box {
  margin: 80px auto 0;
  max-width: 1040px;
  overflow-y: scroll;
  padding-right: 40px;
  width: 100%;
  height: calc(100% - 116px);
}
.contactModal__box.is_hide {
  display: none;
}
.contactModal__box2 {
  margin: 80px auto 0;
  max-width: 1320px;
  padding-right: 40px;
  overflow-y: scroll;
  width: 100%;
  height: calc(100% - 116px);
}
.contactModal__box2.is_hide {
  display: none;
}
.contactModal__ttl {
  border-bottom: solid 1px #202020;
  font-size: 36px;
  padding-bottom: 10px;
  margin-bottom: 60px;
}
.contactModal__ttl.is_center {
  text-align: center;
}
.contactModal__ttl2 {
  font-size: 36px;
  margin-bottom: 30px;
}
.contactModal__ttl2.is_center {
  text-align: center;
}
.contactModal__ttl3 {
  border-bottom: solid 1px #202020;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.contactModal__ttl4 {
  border-bottom: solid 1px #202020;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  margin-top: 30px;
}
.contactModal__ttl4.is_parent {
  background: url(../img/ico/ico_down01.svg) no-repeat right center;
  background-size: 18px 9px;
  cursor: pointer;
  padding-right: 40px;
  transition: 0.3s;
}
.contactModal__ttl4.is_parent.is_open {
  background: url(../img/ico/ico_up01.svg) no-repeat right center;
  background-size: 18px 9px;
}
.contactModal__ttl5 {
  border-bottom: solid 1px #202020;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  margin-top: 30px;
}
.contactModal__txt {
  font-size: 16px;
  text-align: center;
  margin-top: 30px;
}
.contactModal__innerBox {
  margin-bottom: 54px;
}
.contactModal__formBox {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 40px auto 0;
  padding-right: 25px;
}
.contactModal__formBox.is_error .contactModal__formBox__error {
  display: block;
}
.contactModal__formBox.is_error input {
  border: solid 1px #FF0000;
}
.contactModal__formBox__error {
  background: #FCD0DC;
  color: #FF0000;
  display: none;
  font-size: 14px;
  text-align: center;
  margin-top: 3px;
  padding: 0 5px;
  position: absolute;
  left: 0;
  bottom: -25px;
}
.contactModal__formBox__left {
  box-sizing: border-box;
  font-size: 18px;
  padding: 10px 25px;
  max-width: 353px;
  width: 100%;
}
.contactModal__formBox__left .is_require {
  color: #FF0000;
  display: block;
  font-size: 13px;
  margin-left: 6px;
}
.contactModal__formBox__left .is_any {
  color: #6A6A6A;
  display: block;
  font-size: 13px;
  margin-left: 6px;
}
.contactModal__formBox__right {
  box-sizing: border-box;
  display: flex;
  position: relative;
  width: calc(100% - 353px);
}
.contactModal__formBox__tate {
  max-width: 600px;
  width: 100%;
}
.contactModal__formBox__tate input + input {
  margin-top: 15px;
}
.contactModal__formBox__selectBox {
  max-width: 300px;
  width: 100%;
  position: relative;
}
.contactModal__formBox__selectBox.is_left {
  margin-right: auto;
}
.contactModal__formBox__selectBox.is_wide {
  max-width: 360px;
}
.contactModal__formBox__selectBox::before {
  background: url(../img/ico/ico_down01.svg) no-repeat;
  background-size: 100% 100%;
  content: "";
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  pointer-events: none;
  width: 18px;
  height: 9px;
}
.contactModal__formBox__selectBox select {
  appearance: none;
}
.contactModal__formBox__size {
  text-decoration: underline;
  margin-top: auto;
  margin-left: 20px;
}
.contactModal__formBox input, .contactModal__formBox select, .contactModal__formBox textarea {
  background: #fff;
  border: solid 1px #707070;
  box-sizing: border-box;
  display: block;
  font-size: 16px;
  max-width: 600px;
  width: 100%;
}
.contactModal__formBox input.is_half, .contactModal__formBox select.is_half, .contactModal__formBox textarea.is_half {
  max-width: 300px;
  margin-right: auto;
}
.contactModal__formBox input, .contactModal__formBox select {
  height: 52px;
  padding-left: 20px;
}
.contactModal__formBox select {
  color: #202020;
}
.contactModal__formBox textarea {
  resize: none;
  height: 160px;
  padding: 20px;
}
.contactModal__formBox__smallBox {
  display: flex;
  align-items: flex-end;
  max-width: 300px;
  margin-right: auto;
}
.contactModal__formBox__smallBox input {
  max-width: 150px;
}
.contactModal__formBox__smallBox span {
  margin-left: 15px;
}
.contactModal__formBox__txt {
  font-size: 14px;
  text-align: center;
}
.contactModal__agreeBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.contactModal__agreeBox.is_error .contactModal__formBox__error {
  display: block;
  right: 0;
  bottom: -30px;
  margin: auto;
}
.contactModal__agreeBox label {
  cursor: pointer;
  position: relative;
}
.contactModal__agreeBox input[type=checkbox] {
  appearance: none;
  background: #FFFFFF;
  border: solid 1px #707070;
  margin-right: 12px;
  position: relative;
  vertical-align: middle;
  width: 30px;
  height: 30px;
}
.contactModal__agreeBox input[type=checkbox]:checked::before {
  background: url(../img/ico/ico_check01.svg);
  background-size: 15px 10px;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: inline-block;
  width: 15px;
  height: 10px;
}
.contactModal__agreeBox a {
  text-decoration: underline;
}
.contactModal__submitBox {
  margin-top: 40px;
}
.contactModal__submitBox input[type=submit] {
  border: solid 1px #CECECE;
  color: #CECECE;
  font-size: 16px;
  display: block;
  margin: auto;
  padding: 0 20px;
  height: 40px;
  transition: 0.3s;
}
.contactModal__imgBox {
  background: #000;
  display: flex;
  justify-content: center;
  height: 464px;
  padding: 60px;
  margin-bottom: 60px;
}
.contactModal__imgBox__item img {
  width: auto;
  height: 100%;
}
.contactModal__imgBox__item + .contactModal__imgBox__item {
  margin-left: 150px;
}
.contactModal__scrollBox {
  margin-top: 60px;
  padding: 0 30px;
}
.contactModal__accordionBox {
  display: none;
}
.contactModal__customList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 20px 0;
  margin-bottom: -10px;
}
.contactModal__customList__item {
  border-bottom: solid 1px #202020;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding-bottom: 5px;
  width: 47.5%;
  margin-bottom: 20px;
}
.contactModal__customList__item:last-child, .contactModal__customList__item:nth-last-child(2) {
  border-bottom: none;
}
.contactModal__table {
  border-top: solid 1px #000;
  margin: 30px 24px 60px;
  width: calc(100% - 48px);
}
.contactModal__table th, .contactModal__table td {
  border-bottom: solid 1px #000;
  font-size: 14px;
  vertical-align: middle;
  padding: 15px 0;
}
.contactModal__table td {
  border-left: solid 1px #000;
  text-align: center;
}
.contactModal__itemList {
  padding: 20px 20px 0;
  margin-bottom: -10px;
}
.contactModal__itemList__item {
  border-bottom: solid 1px #202020;
  display: flex;
  font-size: 14px;
  padding-bottom: 5px;
  margin-bottom: 20px;
}
.contactModal__itemList__item:last-child {
  border-bottom: none;
}
.contactModal__itemList__item span {
  width: 33.3%;
}
.contactModal__itemList__item .is_third {
  text-align: right;
}
.contactModal__customerBox {
  margin-top: 120px;
}
.contactModal__customerList {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
}
.contactModal__customerList__item {
  width: 46.6%;
}
.contactModal__customerSubList {
  margin-top: 30px;
}
.contactModal__customerSubList__item {
  border-bottom: solid 1px #202020;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  margin-bottom: 30px;
}
.contactModal__customerSubList__item:last-child {
  border-bottom: 0;
}
@media screen and (max-width: 767px) {
  .contactModal {
    margin: 0 auto;
    padding: 20px 10px 40px 20px;
  }
  .contactModal__return {
    font-size: 14px;
    left: 20px;
    top: 20px;
  }
  .contactModal__return__ico {
    margin-right: 6px;
    width: 20px;
    height: 19px;
  }
  .contactModal__box {
    margin: 50px auto 0;
    padding-right: 10px;
    width: 100%;
    height: calc(100% - 75px);
  }
  .contactModal__box2 {
    margin: 50px auto 0;
    overflow-x: hidden;
    padding-right: 10px;
    width: 100%;
    height: calc(100% - 75px);
  }
  .contactModal__ttl {
    font-size: 18px;
    padding-bottom: 6px;
    margin-bottom: 20px;
  }
  .contactModal__ttl.is_center {
    text-align: left;
  }
  .contactModal__ttl2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .contactModal__ttl2.is_center {
    text-align: left;
  }
  .contactModal__ttl3 {
    border-bottom: none;
    font-size: 18px;
    display: block;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .contactModal__ttl3 span {
    border-top: solid 1px #000;
    display: block;
    font-size: 16px;
    padding-top: 10px;
    margin-top: 10px;
  }
  .contactModal__ttl4 {
    font-size: 14px;
    padding-bottom: 10px;
    margin-top: 20px;
  }
  .contactModal__ttl4.is_parent {
    background-position: right top 8px;
    background-size: 15px 7px;
    padding-right: 25px;
  }
  .contactModal__ttl4.is_parent.is_open {
    background-position: right top 8px;
    background-size: 15px 7px;
  }
  .contactModal__ttl5 {
    border-bottom: 0;
    font-size: 14px;
    padding-bottom: 5px;
    padding-left: 10px;
    margin-top: 10px;
    position: relative;
  }
  .contactModal__ttl5::before {
    background: #202020;
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    width: 5px;
    height: 1px;
  }
  .contactModal__txt {
    font-size: 16px;
    text-align: center;
    margin-top: 30px;
  }
  .contactModal__innerBox {
    margin-bottom: 40px;
  }
  .contactModal__formBox {
    display: block;
    margin: 30px auto 0;
    padding-right: 0;
  }
  .contactModal__formBox.is_error .contactModal__formBox__error {
    position: static;
  }
  .contactModal__formBox__left {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 0;
  }
  .contactModal__formBox__left .is_require {
    font-size: 12px;
  }
  .contactModal__formBox__right {
    display: block;
    margin-top: 10px;
    width: 100%;
  }
  .contactModal__formBox__selectBox {
    max-width: 100%;
  }
  .contactModal__formBox__selectBox.is_left {
    margin-right: auto;
  }
  .contactModal__formBox__selectBox.is_wide {
    max-width: 360px;
  }
  .contactModal__formBox__selectBox::before {
    background: url(../img/ico/ico_down01.svg) no-repeat;
    background-size: 100% 100%;
    content: "";
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    pointer-events: none;
    width: 18px;
    height: 9px;
  }
  .contactModal__formBox__selectBox select {
    appearance: none;
  }
  .contactModal__formBox__size {
    font-size: 14px;
    margin-top: 15px;
    margin-left: 0;
  }
  .contactModal__formBox input, .contactModal__formBox select, .contactModal__formBox textarea {
    font-size: 14px;
  }
  .contactModal__formBox input.is_half, .contactModal__formBox select.is_half, .contactModal__formBox textarea.is_half {
    max-width: 100%;
  }
  .contactModal__formBox input, .contactModal__formBox select {
    height: 40px;
    padding-left: 10px;
  }
  .contactModal__formBox textarea {
    height: 120px;
    padding: 20px;
  }
  .contactModal__formBox__smallBox {
    display: flex;
    align-items: flex-end;
    max-width: 300px;
    margin-right: auto;
  }
  .contactModal__formBox__smallBox input {
    max-width: 240px;
  }
  .contactModal__formBox__smallBox span {
    margin-left: 15px;
  }
  .contactModal__formBox__txt {
    font-size: 12px;
  }
  .contactModal__agreeBox {
    font-size: 14px;
    display: block;
    margin: 10px auto 0;
    max-width: 280px;
    width: 100%;
  }
  .contactModal__agreeBox.is_error .contactModal__formBox__error {
    position: static;
  }
  .contactModal__agreeBox label {
    display: block;
  }
  .contactModal__agreeBox input[type=checkbox] {
    display: inline-block;
    vertical-align: 17px;
    width: 20px;
    height: 20px;
  }
  .contactModal__agreeBox__right {
    display: inline-block;
    width: calc(100% - 36px);
  }
  .contactModal__agreeBox a {
    text-decoration: underline;
  }
  .contactModal__submitBox {
    margin-top: 40px;
  }
  .contactModal__submitBox input[type=submit] {
    background: #000;
    border: solid 1px #CECECE;
    color: #CECECE;
    cursor: pointer;
    font-size: 16px;
    display: block;
    margin: auto;
    padding: 0 20px;
    height: 40px;
    transition: 0.3s;
  }
  .contactModal__submitBox input[type=submit]:hover {
    opacity: 0.7;
  }
  .contactModal__imgBox {
    display: flex;
    justify-content: center;
    height: 211px;
    padding: 0;
    margin-left: -20px;
    margin-bottom: 40px;
    width: calc(100% + 40px);
  }
  .contactModal__imgBox__item {
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33.3%;
  }
  .contactModal__imgBox__item img {
    width: auto;
    height: 151px;
  }
  .contactModal__imgBox__item + .contactModal__imgBox__item {
    margin-left: 0;
  }
  .contactModal__scrollBox {
    margin-top: 40px;
    padding: 0;
  }
  .contactModal__accordionBox {
    display: none;
  }
  .contactModal__customList {
    display: block;
    padding: 20px 0 0 20px;
    margin-bottom: 0;
  }
  .contactModal__customList__item {
    border-bottom: solid 1px #BFBFBF;
    flex-wrap: wrap;
    font-size: 14px;
    padding-bottom: 10px;
    width: 100%;
    margin-bottom: 10px;
  }
  .contactModal__customList__item:nth-last-child(2) {
    border-bottom: solid 1px #BFBFBF;
  }
  .contactModal__customList__item span {
    margin-left: auto;
  }
  .contactModal__table {
    border-top: solid 1px #000;
    margin: 30px 24px 60px;
    width: calc(100% - 48px);
  }
  .contactModal__table th, .contactModal__table td {
    border-bottom: solid 1px #000;
    font-size: 14px;
    vertical-align: middle;
    padding: 15px 0;
  }
  .contactModal__table td {
    border-left: solid 1px #000;
    text-align: center;
  }
  .contactModal__itemList {
    padding: 20px 0;
    margin-bottom: -10px;
  }
  .contactModal__itemList__item {
    border-bottom: solid 1px #BFBFBF;
    flex-direction: column;
    font-size: 14px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .contactModal__itemList__item span {
    width: 100%;
  }
  .contactModal__itemList__item .is_first {
    padding-left: 10px;
    position: relative;
  }
  .contactModal__itemList__item .is_first::before {
    background: #202020;
    content: "";
    position: absolute;
    left: 0;
    top: 12px;
    width: 5px;
    height: 1px;
  }
  .contactModal__customerBox {
    margin-top: 40px;
  }
  .contactModal__customerList {
    display: block;
    margin-top: 40px;
  }
  .contactModal__customerList__item {
    width: 100%;
  }
  .contactModal__customerSubList {
    margin-top: 0;
  }
  .contactModal__customerSubList__item {
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .mfp-container {
    padding-left: 0;
    padding-right: 0;
  }
}

.suit__lettering {
  background: #000;
  display: none;
  display: flex;
  position: absolute;
  top: 0;
  left: 250px;
  text-align: left;
  width: 834px;
  height: 100%;
}
.suit__lettering__item {
  padding: 35px 15px;
  width: 33.3%;
}
.suit__lettering__ttl {
  color: #CECECE;
  font-size: 18px;
}
.suit__lettering__ttl.is_margin {
  margin-top: 40px;
}
.suit__lettering__txt {
  color: #CECECE;
  font-size: 12px;
  margin-top: 10px;
}
.suit__lettering__selectBox, .suit__lettering__inputBox {
  margin-top: 20px;
}
.suit__lettering select, .suit__lettering input {
  background: #CECECE;
  box-sizing: border-box;
  color: #707070;
  font-size: 14px;
  width: 100%;
  height: 36px;
}
.suit__lettering select {
  padding-left: 5px;
}
.suit__lettering input {
  padding-left: 10px;
}
.suit__lettering__color {
  text-align: center;
  margin-top: 30px;
}
.suit__lettering__colorSet {
  border: solid 1px #707070;
  border-radius: 50%;
  margin: auto;
  width: 60px;
  height: 60px;
}
.suit__lettering__colorTxt {
  color: #BFBFBF;
  font-size: 14px;
  margin-top: 10px;
}
.suit__lettering__previewBox {
  background: url(../img/bg/bg_preview01.png) no-repeat;
  background-size: 100% 100%;
  border: solid 1px #707070;
  position: relative;
  width: 100%;
  height: 36px;
}
.suit__lettering__previewBox::before {
  background: rgba(0, 0, 0, 0.58);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.suit__lettering__styleRadio {
  margin-top: 20px;
}
.suit__lettering__styleRadio label {
  color: #CECECE;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  margin-top: 25px;
}
.suit__lettering__styleRadio label.is_first {
  margin-top: 20px;
}
.suit__lettering__styleRadio input {
  margin-right: 14px;
  margin-top: 5px;
  width: 20px;
  height: 20px;
}
.suit__lettering__styleRadio img {
  display: block;
  margin-top: 15px;
  width: 140px;
}
.suit__lettering__fontRadio {
  margin-top: 20px;
}
.suit__lettering__fontRadio label {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1;
  margin-top: 18px;
}
.suit__lettering__fontRadio label span {
  color: #707070;
  font-size: 10px;
  width: 35px;
}
.suit__lettering__fontRadio input {
  margin-right: 14px;
  width: 20px;
  height: 20px;
}
.suit__lettering__fontRadio img {
  display: block;
  width: auto;
  height: 18px;
}
@media screen and (max-width: 767px) {
  .suit__lettering {
    background: transparent;
    display: block;
    top: 87px;
    left: 20px;
    text-align: center;
    width: 105px;
    height: 100%;
  }
  .suit__lettering__item {
    background: #0F1113;
    border: solid 1px #707070;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 100%;
    height: 53px;
  }
  .suit__lettering__item a {
    display: block;
    width: 100%;
  }
  .suit__lettering__item + .suit__lettering__item {
    margin-top: 6px;
  }
  .suit__lettering__item.is_color {
    border: none;
    height: 25px;
  }
  .suit__lettering__item.is_disable {
    display: none;
  }
  .suit__lettering__ttl {
    color: #CECECE;
    font-size: 18px;
  }
  .suit__lettering__ttl.is_margin {
    margin-top: 40px;
  }
  .suit__lettering__txt {
    color: #CECECE;
    font-size: 12px;
    margin-top: 10px;
  }
  .suit__lettering__selectBox, .suit__lettering__inputBox {
    margin-top: 20px;
    width: 100%;
  }
  .suit__lettering__selectBox input, .suit__lettering__inputBox input {
    background: #CECECE;
    box-sizing: border-box;
    color: #707070;
    font-size: 14px;
    padding-left: 10px;
    width: 100%;
    height: 46px;
  }
  .suit__lettering__color {
    text-align: center;
    margin-top: 30px;
  }
  .suit__lettering__colorSet {
    border: solid 1px #707070;
    border-radius: 50%;
    margin: auto;
    width: 60px;
    height: 60px;
  }
  .suit__lettering__colorTxt {
    color: #BFBFBF;
    font-size: 14px;
    margin-top: 10px;
  }
  .suit__lettering__previewBox {
    background: url(../img/bg/bg_preview01.png) no-repeat;
    background-size: 100% 100%;
    border: solid 1px #707070;
    position: relative;
    margin-top: 20px;
    width: 100%;
    height: 46px;
  }
  .suit__lettering__previewBox::before {
    background: rgba(0, 0, 0, 0.58);
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .suit__lettering__styleRadio {
    margin-top: 35px;
  }
  .suit__lettering__styleRadio label {
    border-top: solid 1px #707070;
    display: block;
    font-size: 16px;
    margin-top: 20px;
    padding-top: 30px;
  }
  .suit__lettering__styleRadio label.is_first {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }
  .suit__lettering__styleRadio div {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
  .suit__lettering__styleRadio input {
    margin-right: 14px;
    margin-top: 5px;
    width: 20px;
    height: 20px;
  }
  .suit__lettering__styleRadio img {
    display: block;
    margin-top: 15px;
    max-width: 285px;
    width: 100%;
  }
  .suit__lettering__fontRadio {
    margin-top: 20px;
  }
  .suit__lettering__fontRadio label {
    border-top: solid 1px #707070;
    margin-top: 10px;
    padding-top: 20px;
    font-size: 16px;
  }
  .suit__lettering__fontRadio label:nth-child(1) {
    border-top: 0;
  }
  .suit__lettering__fontRadio label span {
    color: #707070;
    font-size: 10px;
    width: 35px;
  }
  .suit__lettering__fontRadio input {
    margin-right: 14px;
    width: 20px;
    height: 20px;
  }
  .suit__lettering__fontRadio img {
    display: block;
    width: auto;
    height: 18px;
  }
  .suit__lettering__selectList {
    margin-top: 20px;
  }
  .suit__lettering__selectList__item {
    border-bottom: solid 1px #707070;
    width: 100%;
    height: 45px;
  }
  .suit__lettering__selectList__item a {
    color: #707070;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
  }
  .suit__lettering__selectList__item a.is_active {
    background-color: #fff;
  }
  .suit__lettering__btnBox {
    padding-bottom: 40px;
  }
  .suit__lettering__btn {
    border: solid 1px #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px auto 10px;
    width: 120px;
    height: 40px;
  }
}

.suit__ventilation {
  background: #000;
  display: none;
  padding: 40px 0 100px;
  position: absolute;
  top: 0;
  left: 250px;
  width: 1192px;
  height: 100%;
}
.suit__ventilation__ttl {
  color: #CECECE;
  font-size: 36px;
}
.suit__ventilation__txt {
  color: #CECECE;
  font-size: 16px;
  margin-top: 30px;
}
.suit__ventilation__box {
  margin: 10px auto 0;
  max-width: 1037px;
  overflow-y: scroll;
  padding-right: 50px;
  width: 100%;
  height: 650px;
}
@media screen and (max-width: 767px) {
  .suit__ventilation {
    padding: 20px 0 0;
    top: 68px;
    left: 0;
    width: 100%;
    height: calc(100% - 68px);
    z-index: 1;
  }
  .suit__ventilation__ttl2 {
    color: #CECECE;
    font-size: 14px;
    text-align: left;
    margin-left: -30px;
  }
  .suit__ventilation__ttl2 img {
    width: 18px;
    margin-left: 10px;
    height: auto;
  }
  .suit__ventilation__txt {
    color: #CECECE;
    font-size: 16px;
    margin-top: 30px;
  }
  .suit__ventilation__box {
    margin: 0 auto;
    overflow-y: scroll;
    padding: 0 30px;
    width: calc(100% - 44px);
    height: 100%;
  }
}

.suit__ventilationList {
  color: #CECECE;
  margin-top: 60px;
}
.suit__ventilationList__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}
.suit__ventilationList__item + .suit__ventilationList__item {
  margin-top: 50px;
}
.suit__ventilationList__imgBox {
  width: 146px;
  height: 146px;
}
.suit__ventilationList__txtBox {
  width: calc(100% - 180px);
}
.suit__ventilationList__ttl {
  font-size: 18px;
}
.suit__ventilationList__txt {
  font-size: 14px;
  line-height: 2;
  margin-top: 10px;
}
@media screen and (max-width: 767px) {
  .suit__ventilationList {
    margin-top: 20px;
    padding-bottom: 60px;
  }
  .suit__ventilationList__item {
    display: block;
  }
  .suit__ventilationList__item + .suit__ventilationList__item {
    margin-top: 30px;
  }
  .suit__ventilationList__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .suit__ventilationList__imgBox {
    width: 120px;
    height: 120px;
  }
  .suit__ventilationList__ttl {
    font-size: 16px;
    width: calc(100% - 140px);
  }
}

.suit__suitSelectList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 100px auto -90px;
  max-width: 900px;
  width: 100%;
}
.suit__suitSelectList::after {
  content: "";
  display: block;
  max-width: 264px;
  width: 100%;
}
.suit__suitSelectList__item {
  color: #CECECE;
  margin-bottom: 90px;
  max-width: 264px;
  width: 100%;
}
.suit__suitSelectList__imgBox {
  margin: auto;
  width: 167px;
  height: 320px;
}
.suit__suitSelectList__ttl {
  font-size: 18px;
  margin-top: 30px;
  line-height: 1.8;
}
.suit__suitSelectList__radio {
  text-align: left;
  margin-top: 40px;
}
.suit__suitSelectList__radio label {
  color: #CECECE;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  margin-top: 10px;
}
.suit__suitSelectList__radio input {
  margin-right: 14px;
  margin-top: 5px;
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 767px) {
  .suit__suitSelectList {
    display: block;
    margin: 30px auto 0;
  }
  .suit__suitSelectList__item {
    margin-bottom: 60px;
    max-width: 100%;
    width: 100%;
  }
  .suit__suitSelectList__imgBox {
    margin: auto;
    width: 167px;
    height: 320px;
  }
  .suit__suitSelectList__ttl {
    font-size: 14px;
    margin-top: 20px;
  }
  .suit__suitSelectList__radio {
    margin: 28px auto 0;
    max-width: 240px;
  }
  .suit__suitSelectList__radio label {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 10px;
  }
  .suit__suitSelectList__radio input {
    margin-right: 14px;
    margin-top: 0;
    width: 12px;
    height: 12px;
  }
}

.suit__others {
  background: #000;
  color: #CECECE;
  display: none;
  padding: 40px 0 100px;
  position: absolute;
  top: 0;
  left: 250px;
  width: 1192px;
  height: 100%;
}
.suit__others__ttl {
  color: #CECECE;
  font-size: 36px;
}
.suit__others__txt {
  color: #CECECE;
  font-size: 16px;
  margin-top: 30px;
}
.suit__others__txt2 {
  color: #CECECE;
  font-size: 14px;
  margin-top: 30px;
}
.suit__others__imgBox {
  margin: 40px auto 0;
  max-width: 413px;
  width: 100%;
}
.suit__others__caption {
  font-size: 16px;
  margin-top: 10px;
}
@media screen and (max-width: 767px) {
  .suit__others {
    padding: 20px 30px 0;
    top: 68px;
    left: 0;
    width: 100%;
    height: calc(100% - 68px);
    z-index: 1;
  }
  .suit__others__txt {
    color: #CECECE;
    font-size: 16px;
    margin-top: 30px;
  }
  .suit__others__ttl2 {
    color: #CECECE;
    font-size: 14px;
    text-align: left;
  }
  .suit__others__ttl2 a {
    display: flex;
    align-items: flex-start;
  }
  .suit__others__ttl2 img {
    padding-top: 3px;
    width: 18px;
    margin-left: 10px;
    height: auto;
  }
  .suit__others__txt2 {
    color: #CECECE;
    font-size: 14px;
    margin-top: 30px;
  }
  .suit__others__caption {
    font-size: 16px;
    margin-top: 10px;
  }
}

.suit__othersRadio {
  display: flex;
  justify-content: space-between;
  margin: 50px auto 0;
  max-width: 893px;
  width: 100%;
}
.suit__othersRadio__item label {
  color: #CECECE;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  margin-top: 10px;
  text-align: left;
}
.suit__othersRadio__item input {
  margin-right: 14px;
  margin-top: 5px;
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 767px) {
  .suit__othersRadio {
    display: block;
    margin: 30px auto 0;
    max-width: 265px;
  }
  .suit__othersRadio__item label {
    font-size: 14px;
    margin-top: 15px;
  }
  .suit__othersRadio__item input {
    margin-right: 10px;
    margin-top: 5px;
    width: 13px;
    height: 13px;
  }
}

.suit__options__item {
  display: none;
}
.suit__options__item.is_select {
  display: block;
}

/** Custom Select **/
.custom-select-wrapper {
  position: relative;
  display: block;
  user-select: none;
}

.custom-select-wrapper select {
  display: none;
}

.custom-select {
  position: relative;
  display: block;
}
.custom-select.is_white .custom-select-trigger {
  background: #fff;
  border: solid 1px #707070;
  color: #202020;
  font-size: 18px;
  height: 48px;
  padding-left: 20px;
}
.custom-select.is_white .custom-select-trigger::after {
  width: 10px;
  height: 10px;
  right: 20px;
  border-bottom: 1px solid #202020;
  border-right: 1px solid #202020;
}
.custom-select.is_white .custom-options {
  margin: 1px 0;
}
.custom-select.is_scroll .custom-options {
  max-height: 300px;
  overflow-y: scroll;
}
@media screen and (max-width: 767px) {
  .custom-select.is_white .custom-select-trigger {
    font-size: 14px;
    height: 40px;
  }
}

.custom-select-trigger {
  background: #CECECE;
  box-sizing: border-box;
  color: #707070;
  font-size: 14px;
  width: 100%;
  height: 36px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
}

.custom-select-trigger:after {
  position: absolute;
  display: block;
  content: "";
  width: 7px;
  height: 7px;
  top: 50%;
  right: 10px;
  margin-top: -3px;
  border-bottom: 1px solid #707070;
  border-right: 1px solid #707070;
  transform: rotate(45deg) translateY(-50%);
  transition: all 0.4s ease-in-out;
  transform-origin: 50% 0;
}

.custom-select.opened .custom-select-trigger:after {
  margin-top: 3px;
  transform: rotate(-135deg) translateY(-50%);
}

.custom-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  min-width: 100%;
  margin: 2px 0;
  border: 1px solid #b5b5b5;
  background: #fff;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateY(-15px);
  z-index: 1;
}

.custom-select.opened .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: translateY(0);
}

.custom-option {
  position: relative;
  display: block;
  padding: 0 10px 0 50px;
  border-bottom: 1px solid #b5b5b5;
  font-size: 14px;
  color: #707070;
  line-height: 40px;
  cursor: pointer;
}

.custom-option:hover {
  color: #343030;
}

.custom-option.selection {
  background: url(../img/ico/ico_check02.svg) no-repeat left 21px center;
  background-size: 13px 8px;
}

/*	js-fadeUp	*/
.js-fadeUp.js-order01 {
  transform: translateY(70px);
  transition: 1s ease;
  opacity: 0;
}
.js-fadeUp.js-order02 {
  transform: translateY(70px);
  transition: 1s ease 0.2s;
  opacity: 0;
}
.js-fadeUp.js-order03 {
  transform: translateY(70px);
  transition: 1s ease 0.4s;
  opacity: 0;
}
.js-fadeUp.js-order04 {
  transform: translateY(70px);
  transition: 1s ease 0.6s;
  opacity: 0;
}
.js-fadeUp.js-order05 {
  transform: translateY(70px);
  transition: 1s ease 0.8s;
  opacity: 0;
}
.js-fadeUp.js-order06 {
  transform: translateY(70px);
  transition: 1s ease 1s;
  opacity: 0;
}
.js-fadeUp.scrollin.js-order01 {
  transform: translateY(0);
  opacity: 1;
}
.js-fadeUp.scrollin.js-order02 {
  transform: translateY(0);
  opacity: 1;
}
.js-fadeUp.scrollin.js-order03 {
  transform: translateY(0);
  opacity: 1;
}
.js-fadeUp.scrollin.js-order04 {
  transform: translateY(0);
  opacity: 1;
}
.js-fadeUp.scrollin.js-order05 {
  transform: translateY(0);
  opacity: 1;
}
.js-fadeUp.scrollin.js-order06 {
  transform: translateY(0);
  opacity: 1;
}

.mfp-content .mfp-close {
  background: url(../img/ico/ico_close01.svg) no-repeat;
  background-size: 100% 100%;
  color: transparent;
  top: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
}
@media screen and (max-width: 767px) {
  .mfp-content .mfp-close {
    top: 15px;
    right: 15px;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #090909 0%, #090909 40%, #202020 40%, #202020 60%, #090909 60%, #090909 100%);
}

::-webkit-scrollbar-thumb {
  background: #BFBFBF;
  border-radius: 5px;
  width: 10px;
}

.mfp-bg {
  opacity: 1;
}

.top_article {
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
}
.top_article::before {
  background-image: url(../img/bg/bg03.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: 1s;
  opacity: 0;
}
.top_article.is_bg_show {
  position: relative;
}
.top_article.is_bg_show::before {
  opacity: 1;
}
.top_article__bg {
  position: relative;
}
.top_article__bg::before {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: 1s;
  opacity: 0;
}
.top_article__bg3::before {
  background-image: url(../img/bg/bg03.jpg);
}
.top_article__bg4::before {
  background-image: url(../img/bg/bg04.jpg);
}
.top_article__bg5::before {
  background-image: url(../img/bg/bg05.jpg);
}
.top_article__bg6::before {
  background-image: url(../img/bg/bg06.jpg);
}
.top_article__box {
  color: #fff;
  text-align: center;
  margin-bottom: 100px;
  max-width: 950px;
  width: 100%;
}
.top_article__txtBox {
  opacity: 0;
  transition: 1.5s;
}
.top_article__ttl {
  font-family: "Arial", sans-serif;
  font-size: 60px;
}
.top_article__ttl span {
  display: block;
  font-size: 24px;
  margin-bottom: 20px;
}
.top_article__lead {
  font-size: 18px;
  margin-top: 20px;
}
.top_article__slider {
  margin: 60px auto 0;
  width: 80%;
  height: 500px;
}
.top_article__slider__wrap {
  transition: 1.5s;
  opacity: 0;
}
.top_article__slider__item {
  background: #000;
  color: #fff;
  cursor: pointer;
  display: flex;
  width: 420px;
  height: 420px;
}
.top_article__slider__item img {
  width: 100%;
  height: auto;
}
.top_article__slider__item.roundabout-in-focus a:hover {
  color: #E6E6E6;
}
.top_article__slider__item a:hover {
  color: #BFBFBF;
}
.top_article__slider__txtBox {
  color: #BFBFBF;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 22px 0 32px 7%;
  position: relative;
  width: 50%;
}
.top_article__slider__imgBox {
  display: flex;
  align-items: center;
  padding: 22px 32px 38px 20px;
  width: 50%;
}
.top_article__slider__ttl {
  font-size: 1.5em;
}
.top_article__slider__txt, .top_article__slider__price {
  font-size: 0.8em;
  margin-top: 6.2%;
}
.top_article__linkList {
  width: 80%;
}
.top_article__linkList__item {
  font-size: 0.9em;
}
.top_article__linkList__item + .top_article__linkList__item {
  margin-top: 15%;
}
.top_article__linkList__item a {
  display: block;
  position: relative;
  transition: 0s;
}
.top_article__linkList__item a::before {
  background: url(../img/ico/ico_right01.svg) right center no-repeat;
  background-size: 100% 100%;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 12%;
  height: 55%;
}
.top_article__btm {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: absolute;
  bottom: 50px;
  left: 50px;
  width: calc(100% - 100px);
}
.top_article__btmTxt {
  color: #fff;
  font-size: 18px;
}
.top_article__copyright {
  color: #fff;
  font-size: 11px;
}
@media screen and (max-width: 767px) {
  .top_article__bg3 {
    background-image: url(../img/bg/bg03_sp.jpg);
  }
  .top_article__bg4 {
    background-image: url(../img/bg/bg04_sp.jpg);
  }
  .top_article__bg5 {
    background-image: url(../img/bg/bg05_sp.jpg);
  }
  .top_article__bg6 {
    background-image: url(../img/bg/bg06_sp.jpg);
  }
  .top_article__box {
    color: #fff;
    text-align: center;
    margin-bottom: 150px;
    width: 100%;
  }
  .top_article__ttl {
    font-size: 38px;
  }
  .top_article__ttl span {
    font-size: 13px;
    margin-bottom: 10px;
  }
  .top_article__lead {
    font-size: 18px;
    margin-top: 20px;
  }
  .top_article__slider {
    margin-top: 30px;
    margin-left: -17%;
    width: 134%;
    height: 300px;
  }
  .top_article__slider__item {
    align-items: center;
    overflow: hidden;
    width: 280px;
    height: 280px;
  }
  .top_article__slider__txtBox {
    padding: 0 0 0 8%;
    width: 55%;
  }
  .top_article__slider__imgBox {
    padding: 12px 7% 23px 7%;
    width: 45%;
  }
  .top_article__slider__ttl {
    font-size: 1.1em;
  }
  .top_article__slider__txt, .top_article__slider__price {
    font-size: 0.8em;
    margin-top: 6.2%;
  }
  .top_article__linkList__item {
    font-size: 0.8em;
    margin-top: 15%;
  }
  .top_article__linkList__item + .top_article__linkList__item {
    margin-top: 10%;
  }
  .top_article__btm {
    display: block;
    position: absolute;
    bottom: 20px;
    left: 30px;
    width: calc(100% - 60px);
  }
  .top_article__btmTxt {
    font-size: 14px;
  }
  .top_article__copyright {
    font-size: 10px;
    text-align: center;
    margin-top: 30px;
  }
}

.loading {
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.loading__box {
  text-align: center;
}
.loading__imgBox {
  animation: 2.5s linear infinite rotation;
  margin: auto;
  width: 120px;
  height: 120px;
}
.loading__txt {
  color: #888;
  font-size: 18px;
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .loading__imgBox {
    width: 74px;
    height: 74px;
  }
  .loading__txt {
    font-size: 11px;
    margin-top: 25px;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0);
  }
}