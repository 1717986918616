.mfp-content {
  .mfp-close {
    background: url(../img/ico/ico_close01.svg) no-repeat;
    background-size: 100% 100%;
    color: transparent;
    top: 25px;
    right: 25px;
    width: 50px;
    height: 50px;
  }
  @include mq {
    .mfp-close {
      top: 15px;
      right: 15px;
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #090909 0%, #090909 40%, #202020 40%, #202020 60%, #090909 60%, #090909 100%);
}
::-webkit-scrollbar-thumb {
  background: #BFBFBF;
  border-radius: 5px;
  width: 10px;
}

.mfp-bg {
  opacity: 1;
}
