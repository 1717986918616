.wrapper {
  overflow: hidden;
  position: relative;
}

.article {
  background: url(../img/bg/bg01.jpg) no-repeat center center;
  background-size: cover;
  display: flex;
  padding: 150px 0 60px;
  position: relative;
  width: 100%;
  height: calc(100vh - 80px);
  min-height: 940px;
  &__box {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
    height: 100%;
    max-height: 768px;
  }
  &__firstAccess {
    background: rgba(#000, .84);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% + 80px);
    z-index: 10000;
  }
  &__center {
    position: relative;
    height: 100%;
    &.is_center {
      margin: auto;
    }
    &.is_option &__lower {
      height: calc(100% - 70px);
    }
    &.is_option &__btn {
      display: block;
    }
    &__lower {
      width: auto;
      height: 100%;
    }
    &__upper {
      position: absolute;
      top: 5px;
      left: 30px;
      width: 352px;
      height: 670px;
      z-index: 10001;
      span {
        background: rgba(#fff, .11);
        border: solid 1px #fff;
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        width: 131px;
        height: 131px;
        transition: .3s;
        &:hover {
          background: rgba(#fff, .4);
        }
        &:nth-child(1) {
          left: 0;
          top: 0;
        }
        &:nth-child(2) {
          right: 0;
          top: 162px;
        }
        &:nth-child(3) {
          left: 50px;
          bottom: 210px;
        }
        &:nth-child(4) {
          right: 30px;
          bottom: 0;
        }
      }
    }
    &__box {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      .ico_pointer01 {
        background: url(../img/ico/ico_pointer01.png) no-repeat;
        background-size: 100% 100%;
        content: "";
        position: absolute;
        right: -15px;
        top: 240px;
        width: 70px;
        height: 87px;
      }
    }
    &__ttl {
      color: #fff;
      font-size: 24px;
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      width: 700px;
      z-index: 10001;
    }
    &__btn {
      margin-top: 70px;
      a {
        background: #0F1113;
        border: solid 1px #CECECE;
        color: #CECECE;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        margin: 30px auto 0 0;
        width: 194px;
        height: 40px;
      }
    }
  }
  &__left {
    background: #000;
    color: #fff;
    text-align: center;
    position: relative;
    width: 250px;
    z-index: 1;
  }
  &__right {
    position: relative;
    max-width: 477px;
    width: 100%;
    &__txt {
      color: #fff;
      font-size: 14px;
      position: absolute;
      right: 0;
      top: -35px;
      img {
        margin-left: 10px;
        vertical-align: middle;
        width: 18px;
        height: 18px;
      }
    }
  }
  @include mq {
    background: url(../img/bg/bg01_sp.jpg) no-repeat center center;
    background-size: cover;
    display: block;
    padding: 0;
    position: relative;
    height: calc(100vh - 88px);
    min-height: initial;
    &__box {
      display: block;
      max-height: initial;
      position: relative;
      .ico_pointer01 {
        right: -4%;
        top: 23vw;
        width: 35px;
        height: 43px;
      }
    }
    &__firstAccess {
      background: rgba(#000, .84);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      z-index: 10000;
    }
    &__center {
      position: relative;
      height: calc(100% - 213px);
      margin-left: auto;
      width: 66.6%;
      &.is_center {
        margin-left: auto;
        margin-right: 0;
      }
      &.is_option &__lower {
        height: auto;
      }
      &.is_option &__btn {
        display: block;
      }
      &__lower {
        position: absolute;
        top: 50px;
        right: 16.4%;
        width: 55%;
        height: auto;
      }
      &__upper {
        top: 50px;
        right: 16.4%;
        left: auto;
        width: 55%;
        height: 70vw;
        span {
          width: 40%;
          height: 15vw;
          &:nth-child(1) {
            left: 0;
            top: 0;
          }
          &:nth-child(2) {
            right: 0;
            top: 15vw;
          }
          &:nth-child(3) {
            left: 8%;
            bottom: 24vw;
          }
          &:nth-child(4) {
            right: 8%;
            bottom: 0;
          }
        }
      }
      &__ttl {
        font-size: 18px;
        position: fixed;
        top: auto;
        bottom: 100px;
        left: 10%;
        transform: translateY(0);
        width: 80%;
      }
      &__btn {
        position: fixed;
        left: 0;
        bottom: 40px;
        margin-top: 0;
        width: 100%;
        a {
          font-size: 15px;
          margin: 0;
          width: 100%;
          height: 50px;
        }
      }
    }
    &__left {
      background: #1D1D1D;
      display: flex;
      margin-top: 48px;
      position: static;
      width: 100%;
    }
    &__right {
      position: static;
      max-width: 477px;
      width: 100%;
      &__txt {
        right: auto;
        left: 10px;
        top: 80px;
        img {
          margin-left: 10px;
        }
      }
    }
  }
}

.suit__colorBox {
  background: #000;
  &.is_coloring.is_select {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      top: 30px;
      right: -17px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 30px 0 30px 17px;
      border-color: transparent transparent transparent #000;
    }
  }
  &.is_select {
    background: #000;
  }
  &.is_select &__content {
    display: block;
    padding: 1px 15px 15px;
    &.is_hide {
      display: none;
    }
  }
  &.is_select &__content2 {
    display: block;
  }
  &.is_select &__ttl {
    padding-top: 30px;
    height: 70px;
    a {
      color: #fff;
    }
  }
  &__ttl {
    font-size: 24px;
    font-weight: normal;
    letter-spacing: .05em;
    height: 80px;
    a {
      color: #707070;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      &:hover {
        color: #343030;
      }
      &:active {
        color: #fff;
      }
    }
  }
  &__content {
    display: none;
  }
  &__content2 {
    background: #000;
    display: none;
    text-align: left;
    padding: 30px 15px;
    position: absolute;
    top: 0;
    left: 250px;
    width: 250px;
    height: 100%;
  }
  &__caution {
    color: #FF0000;
    font-size: 12px;
  }
  &__radio {
    margin-top: 20px;
    margin-left: 10px;
    label {
      color: #CECECE;
      cursor: pointer;
      display: block;
      font-size: 16px;
      margin-top: 10px;
    }
    input {
      margin-right: 14px;
      vertical-align: middle;
      width: 20px;
      height: 20px;
    }
  }
  &__color {
    text-align: center;
    margin-top: 50px;
  }
  &__colorSet {
    border: solid 1px #707070;
    border-radius: 50%;
    margin: auto;
    width: 60px;
    height: 60px;
    a {
      border-radius: 50%;
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
      &:hover::before {
        background: rgba(#fff, .2);
        opacity: 1;
      }
      &:active::before {
        background: rgba(#000, .3);
        opacity: 1;
      }
    }
  }
  &__colorTxt {
    color: #BFBFBF;
    font-size: 16px;
    margin-top: 15px;
  }
  &__ttl2 {
    color: #CECECE;
    font-size: 16px;
    margin-top: 50px;
  }
  &__ttl3 {
    color: #CECECE;
    font-size: 36px;
  }
  &__txt {
    color: #CECECE;
    font-size: 12px;
    margin-top: 10px;
  }
  &__style, &__style2 {
    font-size: 14px;
    margin-top: 10px;
  }
  &__selectBox {
    margin-top: 20px;
    position: relative;
  }
  &__name {
    background: #fff;
    color: #000;
    font-size: 12px;
    padding: 0 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 23px;
  }
  &__btn {
    border: solid 1px #CECECE;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin: 30px auto 0;
    width: 200px;
    height: 46px;
    position: relative;
    z-index: 1;
  }
  &__btn2 {
    color: #707070;
    display: block;
    font-size: 24px;
    margin-top: 30px;
  }
  &__options {
    margin-top: 20px;
    &__item {
      &.is_select {
        a {
          color: #fff;
        }
      }
      a {
        color: #707070;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        height: 50px;
        &:hover {
          color: #343030;
        }
        &:active {
          color: #fff;
        }
      }
    }
  }
  @include mq {
    width: 33.3%;
    height: 40px;
    &.is_coloring.is_select {
      position: static;
    }
    &.is_select {
      background: #000;
    }
    &.is_select &__content {
      padding: 0;
    }
    &.is_select &__content2 {
      display: block;
    }
    &.is_select &__ttl {
      padding-top: 0;
      height: 40px;
    }
    &__ttl {
      font-size: 16px;
      letter-spacing: normal;
      height: 40px;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }
    &__content {
      background: #000;
      display: none;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 1;
      &.is_option {
        top: 40px;
        bottom: auto;
        .suit__colorBox__txt {
          background: #000;
          color: #707070;
          position: fixed;
          padding: 5px 0;
          left: 0;
          bottom: 150px;
          width: 100%;
        }
        .suit__colorList {
          background: #000;
          position: fixed;
          left: 0;
          bottom: 90px;
        }
      }
    }
    &__content2 {
      background: transparent;
      padding: 15px;
      position: absolute;
      top: 40px;
      left: 0;
      width: 100%;
      height: calc(100% - 40px);
    }
    &__caution {
      font-size: 19px;
    }
    &__radio {
      width: 60%;
      margin-top: 20px;
      margin-left: 10px;
      label {
        font-size: 14px;
        margin-top: 20px;
        span {
          font-size: 12px;
        }
      }
      input {
        margin-right: 10px;
      }
    }
    &__box {
      background: #000;
      display: flex;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 65px;
      width: 100%;
      height: 40px;
    }
    &__color {
      display: flex;
      align-items: center;
      margin-top: 0;
      text-align: left;
      & + & {
        margin-left: 28px;
      }
    }
    &__colorSet {
      margin-right: 10px;
      width: 24px;
      height: 24px;
    }
    &__colorTxt {
      color: #fff;
      font-size: 14px;
      margin-top: 0;
    }
    &__ttl2 {
      font-size: 24px;
      margin-top: 0;
      &.is_small_font {
        font-size: 20px
      }
    }
    &__ttl3 {
      color: #CECECE;
      font-size: 36px;
    }
    &__ttl4 {
      font-size: 14px;
      img {
        width: 18px;
        margin-left: 10px;
        height: auto;
      }
    }
    &__txt {
      font-size: 14px;
      margin-top: 20px;
    }
    &__slideArrowSp {
      display: flex;
      align-items: center;
      justify-content: center;
      &__left, &__right {
        img {
          width: 10px;
          height: auto;
        }
      }
      &__left {
        margin-right: 16px;
      }
      &__right {
        margin-left: 16px;
      }
    }
    &__style, &__style2 {
      font-size: 14px;
      margin-top: 0;
    }
    &__selectBox {
      margin-top: 0;
      position: absolute;
      left: 30px;
      bottom: 190px;
      width: 80px;
      height: 80px;
      z-index: 1;
    }
    &__name {
      width: 100%;
    }
    &__btn {
      border: solid 1px #CECECE;
      font-size: 15px;
      margin: 0;
      width: 100%;
      height: 50px;
    }
    &__btn2 {
      color: #707070;
      display: block;
      font-size: 24px;
      margin-top: 30px;
    }
    &__options {
      display: flex;
      margin-top: 0;
      &__item {
        width: 33.3%;
        &.is_select {
          a {
            background: #fff;
            color: #1D1D1D;
          }
        }
        a {
          font-size: 14px;
          height: 27px;
        }
      }
    }
  }
}

.suit__colorList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-left: -2px;
  &.is_lettering {
    display: none;
  }
  &.is_lettering.is_show {
    display: flex;
  }
  &__item {
    margin-left: 2px;
    margin-bottom: 2px;
    width: 35px;
    height: 35px;
    a {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
      &:hover::before {
        background: rgba(#fff, .2);
        opacity: 1;
      }
      &:active::before {
        background: rgba(#000, .3);
        opacity: 1;
      }
    }
  }
  @include mq {
    overflow-x: hidden;
    flex-wrap: nowrap;
    margin-top: 10px;
    margin-left: 0;
    width: 100%;
    &__item {
      margin: 0 10px;
      margin-bottom: 8px;
      width: 40px!important;
      height: 40px;
    }
  }
}

.suit__top {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 421px;
  width: 100%;
  &__imgBox {
    height: 117px;
  }
  &__item {
    height: 117px;
    a {
      display: flex;
      align-items: center;
      height: 100%;
    }
    img {
      width: auto;
      height: 100%;
    }
  }
  &__txt {
    color: #fff;
    font-size: 12px;
    margin-left: 10px;
  }
  @include mq {
    display: block;
    position: absolute;
    left: 30px;
    top: 160px;
    max-width: 22px;
    &__imgBox {
      display: block;
      width: 16px;
      height: 32px;
    }
    &__item {
      text-align: center;
      width: 60px;
      height: 32px;
      margin: auto;
      a {
        width: 100%;
      }
      & + & {
        margin-top: 30px;
      }
    }
  }
}

.suit__middle {
  display: flex;
  justify-content: space-between;
  margin: 70px auto 0;
  &__item {
    height: 400px;
    img {
      width: auto;
      height: 100%;
    }
  }
}

.suit__bottom {
  margin: 60px auto 0;
  &__item {
    a {
      background: #0F1113;
      border: solid 1px #CECECE;
      color: #CECECE;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      margin: auto;
      width: 320px;
      height: 46px;
      span {
        margin-right: 3px;
      }
    }
    & + & {
      margin-top: 20px;
    }
  }
  @include mq {
    position: absolute;
    top: 40px;
    left: 0;
    margin: 0;
    display: flex;
    width: 100%;
    &__item {
      width: 50%;
      a {
        background: #0F1113;
        border: solid 1px #CECECE;
        font-size: 14px;
        width: 100%;
        height: 31px;
      }
      & + & {
        margin-top: 0;
      }
    }
  }
}

.popupBox {
  &__wrap {
    display: none;
    position: relative;
    &::before {
      background: transparent;
      content: "";
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10000;
    }
  }
  &__item {
    background: #DADDE3;
    border: solid 1px #fff;
    box-shadow: 0 3px 6px rgba(#000, .41);
    position: fixed;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    max-width: 700px;
    padding: 55px 15px 60px;
    text-align: center;
    width: 100%;
    z-index: 10000;
  }
  &__ttl {
    font-size: 24px;
  }
  &__txt {
    font-size: 16px;
    margin-top: 20px;
  }
  &__eng {
    font-size: 20px;
    margin-top: 40px;
    line-height: 1.4;
    span {
      font-size: 14px;
      display: block;
      margin-top: 5px;
    }
  }
  &__caution {
    color: #FF0000;
    font-size: 24px;
    margin-top: 30px;
  }
  &__caution2 {
    color: #970005;
    font-size: 14px;
    margin-top: 8px;
  }
  &__btnBox {
    display: flex;
    justify-content: space-between;
    margin: 60px auto 0;
    max-width: 240px;
    width: 100%;
    &.is_center {
      justify-content: center;
    }
    &.is_wide {
      max-width: 370px;
    }
    &.is_wide2 {
      max-width: 500px;
    }
  }
  &__btnBox.is_wide &__btn {
    width: 370px;
  }
  &__btnBox.is_wide2 &__btn.is_wide2 {
    width: 356px;
  }
  &__btn {
    background: #0F1113;
    color: #CECECE;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: 100px;
    height: 40px;
    &.is_btnwide {
      width: 356px;
    }
  }
  &__sendComplete {
    background: #fff;
    .popupBox__eng {
      margin-top: 10px;
    }
  }
  &__sizeBox .popupBox__item {
    background: #fff;
    max-width: 1440px;
    padding: 70px 20px 70px 60px;
  }
  @include mq {
    &__wrap {
      background: rgba(#090909, .8);
      display: none;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      z-index: 9999;
    }
    &__item {
      left: 30px;
      right: auto;
      margin: auto;
      padding: 35px 30px 25px;
      width: calc(100% - 60px);
    }
    &__ttl {
      font-size: 18px;
    }
    &__txt {
      font-size: 14px;
      &.is_sp_big {
        font-size: 18px;
        margin-top: 0;
      }
    }
    &__eng {
      font-size: 14px;
      margin-top: 15px;
    }
    &__caution {
      color: #FF0000;
      font-size: 24px;
      margin-top: 30px;
    }
    &__caution2 {
      font-size: 14px;
      margin-top: 8px;
    }
    &__btnWrap {
      display: flex;
      flex-direction: column-reverse;
    }
    &__btnBox {
      margin: 40px auto 0;
      max-width: 220px;
      &.is_wide {
        margin-top: 20px;
        max-width: 220px;
      }
      &.is_wide2 {
        display: flex;
        flex-direction: column-reverse;
        max-width: 220px;
      }
    }
    &__btnBox.is_wide &__btn {
      height: 60px;
      width: 220px;
    }
    &__btnBox.is_wide2 &__btn {
      margin: 20px auto 0;
    }
    &__btnBox.is_wide2 &__btn.is_wide2 {
      margin-top: 0;
      width: 220px;
      height: 60px;
    }
    &__btn {
      font-size: 15px;
      &.cancel__btn {
        background: #707070;
      }
      &.is_btnwide {
        width: 356px;
      }
    }
    &__sendComplete {
      .popupBox__eng {
        margin-top: 10px;
      }
    }
    &__sizeBox .popupBox__item {
      padding: 30px 20px;
      top: 0;
      left: 0;
      transform: translateY(0);
      overflow-y: scroll;
      width: 100%;
      height: 100vh;
    }
  }
}

.sizeBox {
  background: #fff;
  text-align: left;
  position: fixed;
  left: 0;
  top: 0;
  padding: 60px;
  margin: 0 auto;
  overflow-y: scroll;
  width: 100%;
  height: 100vh;
  z-index: 10000;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: linear-gradient(90deg, #fff 0%, #fff 40%, #BFBFBF 40%, #BFBFBF 60%, #fff 60%, #fff 100%);
  }
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 5px;
    width: 10px;
  }
  &__close {
    background: url(../img/ico/ico_close01.svg) no-repeat;
    background-size: 50px 50px;
    position: absolute;
    top: 25px;
    right: 40px;
    width: 50px;
    height: 50px;
  }
  &__scrollBox {
    overflow-y: visible;
    height: auto;
  }
  &__ttl {
    border-bottom: solid 1px #202020;
    font-size: 18px;
    text-align: left;
    padding-bottom: 10px;
  }
  &__ttl2 {
    font-size: 24px;
    text-align: left;
    margin-top: 50px;
    span {
      font-size: 16px;
      margin-left: 10px;
    }
  }
  &__txt {
    font-size: 14px;
    text-align: left;
    margin: 55px 0;
  }
  &__table {
    border-top: solid 1px #707070;
    border-left: solid 1px #707070;
    margin-top: 10px;
    max-width: 100%;
    width: auto;
    tr {
      &:nth-child(n + 3) {
        th {
          background: #282B2E;
        }
      }
    }
    th, td {
      border-right: solid 1px #707070;
      border-bottom: solid 1px #707070;
      text-align: center;
      padding: 4px;
      vertical-align: middle;
    }
    th {
      background: #0D0F10;
      color: #fff;
      font-size: 14px;
      &.is_big {
        font-size: 16px;
      }
    }
    td {
      font-size: 14px;
      min-width: 60px;
      &:nth-child(2n) {
        background: #D0D3DA;
      }
    }
    .is_font_big {
      th {
        font-size: 16px;
      }
    }
    .bg_white {
      td {
        background: #fff;
      }
    }
  }
  &__list {
    margin-top: 28px;
    &__item {
      font-size: 14px;
      text-align: left;
      margin-bottom: 30px;
    }
  }
  @include mq {
    text-align: left;
    position: relative;
    padding: 30px 10px 30px 20px;
    &__close {
      background-size: 100% 100%;
      top: 20px;
      right: 20px;
      width: 32px;
      height: 32px;
    }
    &__scrollBox {
      overflow-x: hidden;
    }
    &__ttl {
      border-bottom: solid 1px #202020;
      font-size: 18px;
      text-align: left;
      padding-bottom: 10px;
    }
    &__ttl2 {
      font-size: 24px;
      text-align: left;
      margin-top: 50px;
      span {
        font-size: 16px;
        margin-left: 10px;
      }
    }
    &__txt {
      margin: 30px 0;
    }
    &__table {
      border-top: solid 1px #707070;
      border-left: solid 1px #707070;
      margin-top: 10px;
      max-width: 100%;
      width: auto;
      &__wrap {
        overflow-x: scroll;
        padding-bottom: 10px;
        width: 100%;
        &::-webkit-scrollbar {
          width: 100%;
          height: 10px;
        }
        &::-webkit-scrollbar-track {
          background: linear-gradient(0deg, #fff 0%, #fff 40%, #BFBFBF 40%, #BFBFBF 60%, #fff 60%, #fff 100%);
        }
        &::-webkit-scrollbar-thumb {
          background: #707070;
          border-radius: 5px;
          height: 10px;
        }
      }
      & + & {
        margin-top: 30px;
      }
      tr {
        &:nth-child(n + 3) {
          th {
            background: #282B2E;
            min-width: 100px;
          }
        }
      }
      th, td {
        border-right: solid 1px #707070;
        border-bottom: solid 1px #707070;
        text-align: center;
        padding: 4px;
        vertical-align: middle;
      }
      th {
        background: #0D0F10;
        color: #fff;
        font-size: 14px;
        &.is_big {
          font-size: 16px;
        }
      }
      td {
        font-size: 16px;
        min-width: 58px;
      }
      .is_font_big {
        th {
          font-size: 16px;
        }
      }
      .bg_white {
        td {
          background: #fff;
        }
      }
    }
    &__list {
      margin-top: 28px;
      &__item {
        font-size: 14px;
        text-align: left;
        margin-bottom: 30px;
      }
    }
  }
}

.hugeModal {
  background: #090909;
  color: #BFBFBF;
  margin: auto;
  max-width: 1440px;
  position: relative;
  padding: 60px;
  width: 100%;
  height: 900px;
  &__imgBox {
    margin: 50px auto 0;
    max-width: 1230px;
    overflow-y: scroll;
    padding-right: 40px;
    width: 100%;
    height: 580px;
    &__ttl {
      font-size: 16px;
      text-align: center;
    }
  }
  &__ttl {
    font-size: 28px;
    font-family: 'Arial', $gothic;
    margin: auto;
    max-width: 1200px;
    width: 100%;
    span {
      font-size: 16px;
      margin-left: 20px;
    }
    &.is_center {
      text-align: center;
    }
    img {
      margin-left: -20px;
      margin-right: 24px;
      vertical-align: middle;
      width: 73px;
      height: 73px;
    }
  }
  &__txt {
    font-size: 16px;
    text-align: center;
    margin-top: 30px;
  }
  &__smallTxt {
    font-size: 10px;
    text-align: left;
    margin: 5px auto 0;
    max-width: 1200px;
    width: 100%;
  }
  &__saveImgList {
    display: flex;
    justify-content: space-between;
    margin: 70px auto 0;
    max-width: 658px;
    width: 100%;
    &__item {
      height: 330px;
    }
  }
  &__inputBox {
    margin: 60px auto 0;
    input {
      background: #CECECE;
      border: solid 1px #707070;
      box-sizing: border-box;
      display: block;
      font-size: 16px;
      margin: auto;
      max-width: 600px;
      padding-left: 20px;
      width: 100%;
      height: 52px;
    }
    &__error {
      color: #FF0000;
      display: none;
      font-size: 14px;
      text-align: center;
      margin-top: 3px;
      &.is_show {
        display: block;
      }
    }
  }
  &__agreeBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    label {
      cursor: pointer;
    }
    input[type="checkbox"] {
      appearance: none;
      background: #FFFFFF;
      border: solid 1px #707070;
      margin-right: 12px;
      position: relative;
      vertical-align: middle;
      width: 30px;
      height: 30px;
    }
    input[type="checkbox"]:checked::before {
      background: url(../img/ico/ico_check01.svg);
      background-size: 15px 10px;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      display: inline-block;
      width: 15px;
      height: 10px;
    }
    a {
      text-decoration: underline;
    }
    &__error {
      color: #FF0000;
      display: none;
      font-size: 14px;
      text-align: center;
      margin-top: 3px;
      &.is_show {
        display: block;
      }
    }
  }
  &__submitBox {
    margin-top: 40px;
    input[type="submit"] {
      border: solid 1px #CECECE;
      color: #CECECE;
      cursor: pointer;
      font-size: 16px;
      display: block;
      margin: auto;
      width: 200px;
      height: 40px;
    }
  }
  &__btnBox {
    display: flex;
    justify-content: center;
    margin: 90px auto 0;
    max-width: 840px;
    width: 100%;
  }
  &__hugeBtn {
    border: solid 1px #707070;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 100%;
    font-size: 16px;
    max-width: 400px;
    width: 100%;
    height: 40px;
  }
  &__imgList {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    &.is_huge &__item {
      max-width: 380px;
      width: 32%;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    &__item {
      border: solid 1px #000;
      margin-bottom: 20px;
      margin-right: 20px;
      max-width: 180px;
      width: 16%;
      a {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
        &:hover::before {
          background: rgba(#fff, .2);
          opacity: 1;
        }
        &:active::before {
          background: rgba(#000, .3);
          opacity: 1;
        }
      }
      &.is_current {
        background-color: #B3B3B3;
        img {
          filter: brightness(200%);
        }
      }
      &:nth-child(6n) {
        margin-right: 0;
      }
    }
  }
  &__return {
    margin: auto;
    max-width: 1180px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 55px;
    img {
      margin-right: 10px;
      width: 24px;
      height: 23px;
    }
  }
  &__saveList {
    display: flex;
    justify-content: space-between;
    margin: 50px auto 0;
    max-width: 1200px;
    width: 100%;
    height: 700px;
    &__item {
      background: #000;
      max-width: 360px;
      width: 30%;
    }
    &__newSave {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
      &:hover::before {
        background: rgba(#fff, .2);
        opacity: 1;
      }
      &:active::before {
        background: rgba(#000, .3);
        opacity: 1;
      }
    }
    &__imgBox {
      padding: 50px 58px 30px;
      img {
        width: 100%;
        height: auto;
      }
    }
    &__newBox {
      text-align: center;
      img {
        width: 70px;
        height: 70px;
      }
    }
    &__ttl {
      color: #8B8B8B;
      font-size: 18px;
      text-align: center;
      margin-top: 20px;
      span {
        display: block;
      }
    }
    &__bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1px 20px 20px;
    }
    &__replace {
      color: #8B8B8B;
      display: flex;
      font-size: 14px;
      img {
        margin-right: 10px;
        width: 24px;
        height: 23px;
      }
    }
    &__delete {
      img {
        vertical-align: top;
      }
    }
  }
  &__helpList {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 64px;
    &__ttl {
      border-bottom: solid 1px #707070;
      font-size: 24px;
    }
    &__item {
      font-size: 14px;
      width: 45%;
    }
  }
  @include mq {
    background: #000;
    padding: 30px 25px;
    height: 100vh;
    &__imgBox {
      margin: 20px auto 0;
      overflow-y: visible;
      padding-right: 0;
      padding-bottom: 100px;
      height: auto;
    }
    &__ttl {
      font-size: 20px;
      span {
        font-size: 14px;
        display: block;
        margin-left: 0;
        margin-top: 10px;
      }
      &.is_center {
        text-align: center;
      }
      img {
        margin-left: 0;
        margin-right: 12px;
        width: 36px;
        height: 36px;
      }
    }
    &__txt {
      font-size: 14px;
      margin-top: 20px;
    }
    &__saveImgList {
      margin: 46px auto 0;
      max-width: 280px;
      &__item {
        height: 162px;
      }
    }
    &__inputBox {
      margin: 40px auto 0;
      input {
        font-size: 16px;
        height: 40px;
      }
    }
    &__agreeBox {
      font-size: 14px;
      margin: 30px auto 0;
      max-width: 263px;
      width: 100%;
      label {
        display: flex;
      }
      input[type="checkbox"] {
        vertical-align: middle;
        width: 20px;
        height: 20px;
      }
      &__right {
        width: calc(100% - 30px);
      }
    }
    &__submitBox {
      margin-top: 30px;
      input[type="submit"] {
        background: #000;
        border: solid 1px #CECECE;
        color: #CECECE;
        cursor: pointer;
        font-size: 16px;
        display: block;
        margin: auto;
        width: 200px;
        height: 40px;
        transition: .3s;
        &:hover {
          opacity: .7;
        }
      }
    }
    &__btnBox {
      display: block;
      margin: 40px auto 0;
    }
    &__hugeBtn {
      font-size: 15px;
      max-width: 220px;
      height: 60px;
      & + & {
        margin-top: 20px;
      }
    }
    &__btn {
      background: #090909;
      position: fixed;
      left: 0;
      bottom: 40px;
      width: 100%;
    }
    &__imgList {
      &.is_huge &__item {
        margin-right: 2%;
        margin-left: 0;
        max-width: 380px;
        width: 49%;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      &__item {
        margin: 0 1.5% 15px;
        max-width: 180px;
        width: 22%;
        &:nth-child(6n) {
          margin-right: 1.5%;
        }
      }
    }
    &__return {
      background: #090909;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: fixed;
      right: auto;
      bottom: 0;
      height: 40px;
      img {
        margin-right: 5px;
        width: 20px;
        height: auto;
      }
    }
    &__saveList {
      display: block;
      margin: 20px auto 0;
      height: auto;
      &__item {
        background: #000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 360px;
        width: 100%;
        min-height: 340px;
        & + & {
          margin-top: 20px;
        }
      }
      &__newSave {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
      }
      &__imgBox {
        display: flex;
        justify-content: center;
        padding: 20px 15px;
        width: 100%;
        height: 340px;
        img {
          width: auto;
          height: 100%;
        }
      }
      &__newBox {
        img {
          width: 70px;
          height: 70px;
        }
      }
      &__ttl {
        color: #8B8B8B;
        font-size: 18px;
        text-align: center;
        margin-top: 20px;
        span {
          display: block;
        }
      }
      &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1px 20px 20px;
      }
      &__replace {
        align-items: center;
      }
      &__delete {
        img {
          vertical-align: top;
        }
      }
    }
    &__helpList {
      display: block;
      margin-top: 20px;
      margin-bottom: 52px;
      &__item {
        width: 100%;
        & + & {
          margin-top: 25px;
        }
      }
    }
  }
}

.mfp-wrap {
  @include mq {
    background: #090909;
  }
}

.contactModal {
  background: #fff;
  color: #202020;
  margin: 0 auto;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  padding: 60px;
  width: 100%;
  height: 100vh;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: linear-gradient(90deg, #fff 0%, #fff 40%, #BFBFBF 40%, #BFBFBF 60%, #fff 60%, #fff 100%);
  }
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 5px;
    width: 10px;
  }
  &__return {
    display: flex;
    align-items: center;
    position: absolute;
    left: 40px;
    top: 40px;
    &__ico {
      margin-right: 15px;
      width: 37px;
      height: 36px;
    }
  }
  &__box {
    margin: 80px auto 0;
    max-width: 1040px;
    overflow-y: scroll;
    padding-right: 40px;
    width: 100%;
    height: calc(100% - 116px);
    &.is_hide {
      display: none;
    }
  }
  &__box2 {
    margin: 80px auto 0;
    max-width: 1320px;
    padding-right: 40px;
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 116px);
    &.is_hide {
      display: none;
    }
  }
  &__ttl {
    border-bottom: solid 1px #202020;
    font-size: 36px;
    padding-bottom: 10px;
    margin-bottom: 60px;
    &.is_center {
      text-align: center;
    }
  }
  &__ttl2 {
    font-size: 36px;
    margin-bottom: 30px;
    &.is_center {
      text-align: center;
    }
  }
  &__ttl3 {
    border-bottom: solid 1px #202020;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  &__ttl4 {
    border-bottom: solid 1px #202020;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    margin-top: 30px;
    &.is_parent {
      background: url(../img/ico/ico_down01.svg) no-repeat right center;
      background-size: 18px 9px;
      cursor: pointer;
      padding-right: 40px;
      transition: .3s;
    }
    &.is_parent.is_open {
      background: url(../img/ico/ico_up01.svg) no-repeat right center;
      background-size: 18px 9px;
    }
  }
  &__ttl5 {
    border-bottom: solid 1px #202020;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    margin-top: 30px;
  }
  &__txt {
    font-size: 16px;
    text-align: center;
    margin-top: 30px;
  }
  &__innerBox {
    margin-bottom: 54px;
  }
  &__formBox {
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 40px auto 0;
    padding-right: 25px;
    &.is_error &__error {
      display: block;
    }
    &.is_error input {
      border: solid 1px #FF0000;
    }
    &__error {
      background: #FCD0DC;
      color: #FF0000;
      display: none;
      font-size: 14px;
      text-align: center;
      margin-top: 3px;
      padding: 0 5px;
      position: absolute;
      left: 0;
      bottom: -25px;
    }
    &__left {
      box-sizing: border-box;
      font-size: 18px;
      padding: 10px 25px;
      max-width: 353px;
      width: 100%;
      .is_require {
        color: #FF0000;
        display: block;
        font-size: 13px;
        margin-left: 6px;
      }
      .is_any {
        color: #6A6A6A;
        display: block;
        font-size: 13px;
        margin-left: 6px;
      }
    }
    &__right {
      box-sizing: border-box;
      display: flex;
      position: relative;
      width: calc(100% - 353px);
    }
    &__tate {
      max-width: 600px;
      width: 100%;
      input + input {
        margin-top: 15px;
      }
    }
    &__selectBox {
      max-width: 300px;
      width: 100%;
      position: relative;
      &.is_left {
        margin-right: auto;
      }
      &.is_wide {
        max-width: 360px;
      }
      &::before {
        background: url(../img/ico/ico_down01.svg) no-repeat;
        background-size: 100% 100%;
        content: "";
        position: absolute;
        right: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
        pointer-events: none;
        width: 18px;
        height: 9px;
      }
      select {
        appearance: none;
      }
    }
    &__size {
      text-decoration: underline;
      margin-top: auto;
      margin-left: 20px;
    }
    input, select, textarea {
      background: #fff;
      border: solid 1px #707070;
      box-sizing: border-box;
      display: block;
      font-size: 16px;
      max-width: 600px;
      width: 100%;
      &.is_half {
        max-width: 300px;
        margin-right: auto;
      }
    }
    input, select {
      height: 52px;
      padding-left: 20px;
    }
    select {
      color: #202020;
    }
    textarea {
      resize: none;
      height: 160px;
      padding: 20px;
    }
    &__smallBox {
      display: flex;
      align-items: flex-end;
      max-width: 300px;
      margin-right: auto;
      input {
        max-width: 150px;
      }
      span {
        margin-left: 15px;
      }
    }
    &__txt {
      font-size: 14px;
      text-align: center;
    }
  }
  &__agreeBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    &.is_error .contactModal__formBox__error {
      display: block;
      right: 0;
      bottom: -30px;
      margin: auto;
    }
    label {
      cursor: pointer;
      position: relative;
    }
    input[type="checkbox"] {
      appearance: none;
      background: #FFFFFF;
      border: solid 1px #707070;
      margin-right: 12px;
      position: relative;
      vertical-align: middle;
      width: 30px;
      height: 30px;
    }
    input[type="checkbox"]:checked::before {
      background: url(../img/ico/ico_check01.svg);
      background-size: 15px 10px;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      display: inline-block;
      width: 15px;
      height: 10px;
    }
    a {
      text-decoration: underline;
    }
  }
  &__submitBox {
    margin-top: 40px;
    input[type="submit"] {
      border: solid 1px #CECECE;
      color: #CECECE;
      font-size: 16px;
      display: block;
      margin: auto;
      padding: 0 20px;
      height: 40px;
      transition: .3s;
    }
  }
  &__imgBox {
    background: #000;
    display: flex;
    justify-content: center;
    height: 464px;
    padding: 60px;
    margin-bottom: 60px;
    &__item {
      img {
        width: auto;
        height: 100%;
      }
      & + & {
        margin-left: 150px;
      }
    }
  }
  &__scrollBox {
    margin-top: 60px;
    padding: 0 30px;
  }
  &__accordionBox {
    display: none;
  }
  &__customList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 20px 0;
    margin-bottom: -10px;
    &__item {
      border-bottom: solid 1px #202020;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      padding-bottom: 5px;
      width: 47.5%;
      margin-bottom: 20px;
      &:last-child, &:nth-last-child(2) {
        border-bottom: none;
      }
    }
  }
  &__table {
    border-top: solid 1px #000;
    margin: 30px 24px 60px;
    width: calc(100% - 48px);
    th, td {
      border-bottom: solid 1px #000;
      font-size: 14px;
      vertical-align: middle;
      padding: 15px 0;
    }
    td {
      border-left: solid 1px #000;
      text-align: center;
    }
  }
  &__itemList {
    padding: 20px 20px 0;
    margin-bottom: -10px;
    &__item {
      border-bottom: solid 1px #202020;
      display: flex;
      font-size: 14px;
      padding-bottom: 5px;
      margin-bottom: 20px;
      &:last-child {
        border-bottom: none;
      }
      span {
        width: 33.3%;
      }
      .is_third {
        text-align: right;
      }
    }
  }
  &__customerBox {
    margin-top: 120px;
  }
  &__customerList {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    &__item {
      width: 46.6%;
    }
  }
  &__customerSubList {
    margin-top: 30px;
    &__item {
      border-bottom: solid 1px #202020;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      padding-bottom: 5px;
      margin-bottom: 30px;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  @include mq {
    margin: 0 auto;
    padding: 20px 10px 40px 20px;
    &__return {
      font-size: 14px;
      left: 20px;
      top: 20px;
      &__ico {
        margin-right: 6px;
        width: 20px;
        height: 19px;
      }
    }
    &__box {
      margin: 50px auto 0;
      padding-right: 10px;
      width: 100%;
      height: calc(100% - 75px);
    }
    &__box2 {
      margin: 50px auto 0;
      overflow-x: hidden;
      padding-right: 10px;
      width: 100%;
      height: calc(100% - 75px);
    }
    &__ttl {
      font-size: 18px;
      padding-bottom: 6px;
      margin-bottom: 20px;
      &.is_center {
        text-align: left;
      }
    }
    &__ttl2 {
      font-size: 18px;
      margin-bottom: 10px;
      &.is_center {
        text-align: left;
      }
    }
    &__ttl3 {
      border-bottom: none;
      font-size: 18px;
      display: block;
      justify-content: space-between;
      padding-bottom: 10px;
      span {
        border-top: solid 1px #000;
        display: block;
        font-size: 16px;
        padding-top: 10px;
        margin-top: 10px;
      }
    }
    &__ttl4 {
      font-size: 14px;
      padding-bottom: 10px;
      margin-top: 20px;
      &.is_parent {
        background-position: right top 8px;
        background-size: 15px 7px;
        padding-right: 25px;
      }
      &.is_parent.is_open {
        background-position: right top 8px;
        background-size: 15px 7px;
      }
    }
    &__ttl5 {
      border-bottom: 0;
      font-size: 14px;
      padding-bottom: 5px;
      padding-left: 10px;
      margin-top: 10px;
      position: relative;
      &::before {
        background: #202020;
        content: "";
        position: absolute;
        left: 0;
        top: 10px;
        width: 5px;
        height: 1px;
      }
    }
    &__txt {
      font-size: 16px;
      text-align: center;
      margin-top: 30px;
    }
    &__innerBox {
      margin-bottom: 40px;
    }
    &__formBox {
      display: block;
      margin: 30px auto 0;
      padding-right: 0;
      &.is_error &__error {
        position: static;
      }
      &__left {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        padding: 0;
        .is_require {
          font-size: 12px;
        }
      }
      &__right {
        display: block;
        margin-top: 10px;
        width: 100%;
      }
      &__selectBox {
        max-width: 100%;
        &.is_left {
          margin-right: auto;
        }
        &.is_wide {
          max-width: 360px;
        }
        &::before {
          background: url(../img/ico/ico_down01.svg) no-repeat;
          background-size: 100% 100%;
          content: "";
          position: absolute;
          right: 20px;
          top: 0;
          bottom: 0;
          margin: auto;
          pointer-events: none;
          width: 18px;
          height: 9px;
        }
        select {
          appearance: none;
        }
      }
      &__size {
        font-size: 14px;
        margin-top: 15px;
        margin-left: 0;
      }
      input, select, textarea {
        font-size: 14px;
        &.is_half {
          max-width: 100%;
        }
      }
      input, select {
        height: 40px;
        padding-left: 10px;
      }
      textarea {
        height: 120px;
        padding: 20px;
      }
      &__smallBox {
        display: flex;
        align-items: flex-end;
        max-width: 300px;
        margin-right: auto;
        input {
          max-width: 240px;
        }
        span {
          margin-left: 15px;
        }
      }
      &__txt {
        font-size: 12px;
      }
    }
    &__agreeBox {
      font-size: 14px;
      display: block;
      margin: 10px auto 0;
      max-width: 280px;
      width: 100%;
      &.is_error .contactModal__formBox__error {
        position: static;
      }
      label {
        display: block;
      }
      input[type="checkbox"] {
        display: inline-block;
        vertical-align: 17px;
        width: 20px;
        height: 20px;
      }
      &__right {
        display: inline-block;
        width: calc(100% - 36px);
      }
      a {
        text-decoration: underline;
      }
    }
    &__submitBox {
      margin-top: 40px;
      input[type="submit"] {
        background: #000;
        border: solid 1px #CECECE;
        color: #CECECE;
        cursor: pointer;
        font-size: 16px;
        display: block;
        margin: auto;
        padding: 0 20px;
        height: 40px;
        transition: .3s;
        &:hover {
          opacity: .7;
        }
      }
    }
    &__imgBox {
      display: flex;
      justify-content: center;
      height: 211px;
      padding: 0;
      margin-left: -20px;
      margin-bottom: 40px;
      width: calc(100% + 40px);
      &__item {
        background: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33.3%;
        img {
          width: auto;
          height: 151px;
        }
        & + & {
          margin-left: 0;
        }
      }
    }
    &__scrollBox {
      margin-top: 40px;
      padding: 0;
    }
    &__accordionBox {
      display: none;
    }
    &__customList {
      display: block;
      padding: 20px 0 0 20px;
      margin-bottom: 0;
      &__item {
        border-bottom: solid 1px #BFBFBF;
        flex-wrap: wrap;
        font-size: 14px;
        padding-bottom: 10px;
        width: 100%;
        margin-bottom: 10px;
        &:nth-last-child(2) {
          border-bottom: solid 1px #BFBFBF;
        }
        span {
          margin-left: auto;
        }
      }
    }
    &__table {
      border-top: solid 1px #000;
      margin: 30px 24px 60px;
      width: calc(100% - 48px);
      th, td {
        border-bottom: solid 1px #000;
        font-size: 14px;
        vertical-align: middle;
        padding: 15px 0;
      }
      td {
        border-left: solid 1px #000;
        text-align: center;
      }
    }
    &__itemList {
      padding: 20px 0;
      margin-bottom: -10px;
      &__item {
        border-bottom: solid 1px #BFBFBF;
        flex-direction: column;
        font-size: 14px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        span {
          width: 100%;
        }
        .is_first {
          padding-left: 10px;
          position: relative;
          &::before {
            background: #202020;
            content: "";
            position: absolute;
            left: 0;
            top: 12px;
            width: 5px;
            height: 1px;
          }
        }
      }
    }
    &__customerBox {
      margin-top: 40px;
    }
    &__customerList {
      display: block;
      margin-top: 40px;
      &__item {
        width: 100%;
      }
    }
    &__customerSubList {
      margin-top: 0;
      &__item {
        padding-bottom: 10px;
        margin-bottom: 20px;
      }
    }
  }
}

.mfp-container {
  @include mq {
    padding-left: 0;
    padding-right: 0;
  }
}

.suit__lettering {
  background: #000;
  display: none;
  display: flex;
  position: absolute;
  top: 0;
  left: 250px;
  text-align: left;
  width: 834px;
  height: 100%;
  &__item {
    padding: 35px 15px;
    width: 33.3%;
  }
  &__ttl {
    color: #CECECE;
    font-size: 18px;
    &.is_margin {
      margin-top: 40px;
    }
  }
  &__txt {
    color: #CECECE;
    font-size: 12px;
    margin-top: 10px;
  }
  &__selectBox, &__inputBox {
    margin-top: 20px;
  }
  select, input {
    background: #CECECE;
    box-sizing: border-box;
    color: #707070;
    font-size: 14px;
    width: 100%;
    height: 36px;
  }
  select {
    padding-left: 5px;
  }
  input {
    padding-left: 10px;
  }
  &__color {
    text-align: center;
    margin-top: 30px;
  }
  &__colorSet {
    border: solid 1px #707070;
    border-radius: 50%;
    margin: auto;
    width: 60px;
    height: 60px;
  }
  &__colorTxt {
    color: #BFBFBF;
    font-size: 14px;
    margin-top: 10px;
  }
  &__previewBox {
    background: url(../img/bg/bg_preview01.png) no-repeat;
    background-size: 100% 100%;
    border: solid 1px #707070;
    position: relative;
    width: 100%;
    height: 36px;
    &::before {
      background: rgba(#000, .58);
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  &__styleRadio {
    margin-top: 20px;
    label {
      color: #CECECE;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      font-size: 16px;
      margin-top: 25px;
      &.is_first {
        margin-top: 20px;
      }
    }
    input {
      margin-right: 14px;
      margin-top: 5px;
      width: 20px;
      height: 20px;
    }
    img {
      display: block;
      margin-top: 15px;
      width: 140px;
    }
  }
  &__fontRadio {
    margin-top: 20px;
    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 1;
      margin-top: 18px;
      span {
        color: #707070;
        font-size: 10px;
        width: 35px;
      }
    }
    input {
      margin-right: 14px;
      width: 20px;
      height: 20px;
    }
    img {
      display: block;
      width: auto;
      height: 18px;
    }
  }
  @include mq {
    background: transparent;
    display: block;
    top: 87px;
    left: 20px;
    text-align: center;
    width: 105px;
    height: 100%;
    &__item {
      background: #0F1113;
      border: solid 1px #707070;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 100%;
      height: 53px;
      a {
        display: block;
        width: 100%;
      }
      & + & {
        margin-top: 6px;
      }
      &.is_color {
        border: none;
        height: 25px;
      }
      &.is_disable {
        display: none;
      }
    }
    &__ttl {
      color: #CECECE;
      font-size: 18px;
      &.is_margin {
        margin-top: 40px;
      }
    }
    &__txt {
      color: #CECECE;
      font-size: 12px;
      margin-top: 10px;
    }
    &__selectBox, &__inputBox {
      margin-top: 20px;
      width: 100%;
      input {
        background: #CECECE;
        box-sizing: border-box;
        color: #707070;
        font-size: 14px;
        padding-left: 10px;
        width: 100%;
        height: 46px;
      }
    }
    &__color {
      text-align: center;
      margin-top: 30px;
    }
    &__colorSet {
      border: solid 1px #707070;
      border-radius: 50%;
      margin: auto;
      width: 60px;
      height: 60px;
    }
    &__colorTxt {
      color: #BFBFBF;
      font-size: 14px;
      margin-top: 10px;
    }
    &__previewBox {
      background: url(../img/bg/bg_preview01.png) no-repeat;
      background-size: 100% 100%;
      border: solid 1px #707070;
      position: relative;
      margin-top: 20px;
      width: 100%;
      height: 46px;
      &::before {
        background: rgba(#000, .58);
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
    &__styleRadio {
      margin-top: 35px;
      label {
        border-top: solid 1px #707070;
        display: block;
        font-size: 16px;
        margin-top: 20px;
        padding-top: 30px;
        &.is_first {
          border-top: 0;
          margin-top: 0;
          padding-top: 0;
        }
      }
      div {
        display: flex;
        align-items: center;
        margin-top: 30px;
      }
      input {
        margin-right: 14px;
        margin-top: 5px;
        width: 20px;
        height: 20px;
      }
      img {
        display: block;
        margin-top: 15px;
        max-width: 285px;
        width: 100%;
      }
    }
    &__fontRadio {
      margin-top: 20px;
      label {
        border-top: solid 1px #707070;
        margin-top: 10px;
        padding-top: 20px;
        font-size: 16px;
        &:nth-child(1) {
          border-top: 0;
        }
        span {
          color: #707070;
          font-size: 10px;
          width: 35px;
        }
      }
      input {
        margin-right: 14px;
        width: 20px;
        height: 20px;
      }
      img {
        display: block;
        width: auto;
        height: 18px;
      }
    }
    &__selectList {
      margin-top: 20px;
      &__item {
        border-bottom: solid 1px #707070;
        width: 100%;
        height: 45px;
        a {
          color: #707070;
          display: flex;
          align-items: center;
          height: 100%;
          padding: 0 10px;
          &.is_active {
            background-color: #fff;
          }
        }
      }
    }
    &__btnBox {
      padding-bottom: 40px;
    }
    &__btn {
      border: solid 1px #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 40px auto 10px;
      width: 120px;
      height: 40px;
    }
  }
}

.suit__ventilation {
  background: #000;
  display: none;
  padding: 40px 0 100px;
  position: absolute;
  top: 0;
  left: 250px;
  width: 1192px;
  height: 100%;
  &__ttl {
    color: #CECECE;
    font-size: 36px;
  }
  &__txt {
    color: #CECECE;
    font-size: 16px;
    margin-top: 30px;
  }
  &__box {
    margin: 10px auto 0;
    max-width: 1037px;
    overflow-y: scroll;
    padding-right: 50px;
    width: 100%;
    height: 650px;
  }
  @include mq {
    padding: 20px 0 0;
    top: 68px;
    left: 0;
    width: 100%;
    height: calc(100% - 68px);
    z-index: 1;
    &__ttl2 {
      color: #CECECE;
      font-size: 14px;
      text-align: left;
      margin-left: -30px;
      img {
        width: 18px;
        margin-left: 10px;
        height: auto;
      }
    }
    &__txt {
      color: #CECECE;
      font-size: 16px;
      margin-top: 30px;
    }
    &__box {
      margin: 0 auto;
      overflow-y: scroll;
      padding: 0 30px;
      width: calc(100% - 44px);
      height: 100%;
    }
  }
}

.suit__ventilationList {
  color: #CECECE;
  margin-top: 60px;
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    & + & {
      margin-top: 50px;
    }
  }
  &__imgBox {
    width: 146px;
    height: 146px;
  }
  &__txtBox {
    width: calc(100% - 180px);
  }
  &__ttl {
    font-size: 18px;
  }
  &__txt {
    font-size: 14px;
    line-height: 2;
    margin-top: 10px;
  }
  @include mq {
    margin-top: 20px;
    padding-bottom: 60px;
    &__item {
      display: block;
      & + & {
        margin-top: 30px;
      }
    }
    &__top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
    }
    &__imgBox {
      width: 120px;
      height: 120px;
    }
    &__ttl {
      font-size: 16px;
      width: calc(100% - 140px);
    }
  }
}

.suit__suitSelectList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 100px auto -90px;
  max-width: 900px;
  width: 100%;
  &::after {
    content: "";
    display: block;
    max-width: 264px;
    width: 100%;
  }
  &__item {
    color: #CECECE;
    margin-bottom: 90px;
    max-width: 264px;
    width: 100%;
  }
  &__imgBox {
    margin: auto;
    width: 167px;
    height: 320px;
  }
  &__ttl {
    font-size: 18px;
    margin-top: 30px;
    line-height: 1.8;
  }
  &__radio {
    text-align: left;
    margin-top: 40px;
    label {
      color: #CECECE;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      margin-top: 10px;
    }
    input {
      margin-right: 14px;
      margin-top: 5px;
      width: 20px;
      height: 20px;
    }
  }
  @include mq {
    display: block;
    margin: 30px auto 0;
    &__item {
      margin-bottom: 60px;
      max-width: 100%;
      width: 100%;
    }
    &__imgBox {
      margin: auto;
      width: 167px;
      height: 320px;
    }
    &__ttl {
      font-size: 14px;
      margin-top: 20px;
    }
    &__radio {
      margin: 28px auto 0;
      max-width: 240px;
      label {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-top: 10px;
      }
      input {
        margin-right: 14px;
        margin-top: 0;
        width: 12px;
        height: 12px;
      }
    }
  }
}

.suit__others {
  background: #000;
  color: #CECECE;
  display: none;
  padding: 40px 0 100px;
  position: absolute;
  top: 0;
  left: 250px;
  width: 1192px;
  height: 100%;
  &__ttl {
    color: #CECECE;
    font-size: 36px;
  }
  &__txt {
    color: #CECECE;
    font-size: 16px;
    margin-top: 30px;
  }
  &__txt2 {
    color: #CECECE;
    font-size: 14px;
    margin-top: 30px;
  }
  &__imgBox {
    margin: 40px auto 0;
    max-width: 413px;
    width: 100%;
  }
  &__caption {
    font-size: 16px;
    margin-top: 10px;
  }
  @include mq {
    padding: 20px 30px 0;
    top: 68px;
    left: 0;
    width: 100%;
    height: calc(100% - 68px);
    z-index: 1;
    &__txt {
      color: #CECECE;
      font-size: 16px;
      margin-top: 30px;
    }
    &__ttl2 {
      color: #CECECE;
      font-size: 14px;
      text-align: left;
      a {
        display: flex;
        align-items: flex-start;
      }
      img {
        padding-top: 3px;
        width: 18px;
        margin-left: 10px;
        height: auto;
      }
    }
    &__txt2 {
      color: #CECECE;
      font-size: 14px;
      margin-top: 30px;
    }
    &__caption {
      font-size: 16px;
      margin-top: 10px;
    }
  }
}

.suit__othersRadio {
  display: flex;
  justify-content: space-between;
  margin: 50px auto 0;
  max-width: 893px;
  width: 100%;
  &__item {
    label {
      color: #CECECE;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      margin-top: 10px;
      text-align: left;
    }
    input {
      margin-right: 14px;
      margin-top: 5px;
      width: 20px;
      height: 20px;
    }
  }
  @include mq {
    display: block;
    margin: 30px auto 0;
    max-width: 265px;
    &__item {
      label {
        font-size: 14px;
        margin-top: 15px;
      }
      input {
        margin-right: 10px;
        margin-top: 5px;
        width: 13px;
        height: 13px;
      }
    }
  }
}

.suit__options {
  &__item {
    display: none;
    &.is_select {
      display: block;
    }
  }
}

/** Custom Select **/
.custom-select-wrapper {
  position: relative;
  display: block;
  user-select: none;
}
.custom-select-wrapper select {
  display: none;
}
.custom-select {
  position: relative;
  display: block;
  &.is_white {
    .custom-select-trigger {
      background: #fff;
      border: solid 1px #707070;
      color: #202020;
      font-size: 18px;
      height: 48px;
      padding-left: 20px;
      &::after {
        width: 10px;
        height: 10px;
        right: 20px;
        border-bottom: 1px solid #202020;
        border-right: 1px solid #202020;
      }
    }
    .custom-options {
      margin: 1px 0;
    }
  }
  &.is_scroll {
    .custom-options {
      max-height: 300px;
      overflow-y: scroll;
    }
  }
  @include mq {
    &.is_white {
      .custom-select-trigger {
        font-size: 14px;
        height: 40px;
      }
    }
  }
}
.custom-select-trigger {
  background: #CECECE;
  box-sizing: border-box;
  color: #707070;
  font-size: 14px;
  width: 100%;
  height: 36px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
}
.custom-select-trigger:after {
  position: absolute;
  display: block;
  content: '';
  width: 7px;
  height: 7px;
  top: 50%;
  right: 10px;
  margin-top: -3px;
  border-bottom: 1px solid #707070;
  border-right: 1px solid #707070;
  transform: rotate(45deg) translateY(-50%);
  transition: all .4s ease-in-out;
  transform-origin: 50% 0;
}
.custom-select.opened .custom-select-trigger:after {
  margin-top: 3px;
  transform: rotate(-135deg) translateY(-50%);
}
.custom-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  min-width: 100%;
  margin: 2px 0;
  border: 1px solid #b5b5b5;
  background: #fff;
  transition: all .4s ease-in-out;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateY(-15px);
  z-index: 1;
}
.custom-select.opened .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: translateY(0);
}
.custom-option {
  position: relative;
  display: block;
  padding: 0 10px 0 50px;
  border-bottom: 1px solid #b5b5b5;
  font-size: 14px;
  color: #707070;
  line-height: 40px;
  cursor: pointer;
}
.custom-option:hover {
  color: #343030;
}
.custom-option.selection {
  background: url(../img/ico/ico_check02.svg) no-repeat left 21px center;
  background-size: 13px 8px;
}
