.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  max-width: 1440px;
  padding-top: 47px;
  width: 100%;
  z-index: 10;
  &__left {
    display: flex;
    align-items: flex-end;
  }
  &__right {
    display: flex;
    align-items: flex-end;
  }
  &__ventilation {
    color: #fff;
    display: none;
    font-size: 18px;
    margin-right: 20px;
    &.is_show {
      display: block;
    }
  }
  &__lettering {
    color: #fff;
    display: none;
    font-size: 18px;
    margin-right: 20px;
    &.is_show {
      display: block;
    }
  }
  &__others {
    color: #fff;
    display: none;
    font-size: 18px;
    margin-right: 20px;
    &.is_show {
      display: block;
    }
  }
  &__logo {
    width: 261px;
    height: 47px;
  }
  &__lead {
    color: #fff;
    font-size: 14px;
    margin-left: 8px;
  }
  &__price {
    border: solid 1px #fff;
    color: #fff;
    font-size: 24px;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: .05em;
    width: 200px;
    height: 50px;
  }
  @include mq {
    background: #000;
    height: 48px;
    padding-top: 0;
    padding-left: 20px;
    &.is_atop {
      background: transparent;
    }
    &__right {
      align-items: center;
    }
    &__ventilation {
      font-size: 10px;
      margin-right: 20px;
    }
    &__lettering {
      font-size: 10px;
      margin-right: 20px;
    }
    &__others {
      font-size: 10px;
      margin-right: 20px;
    }
    &__logo {
      width: 89px;
      height: 14px;
      a {
        display: block;
        width: 100%;
        height: 100%;
        img {
          vertical-align: top;
        }
      }
    }
    &__lead {
      color: #fff;
      font-size: 14px;
      margin-left: 8px;
    }
    &__price {
      border: 0;
      font-size: 14px;
      width: auto;
      height: auto;
      margin-top: 0;
      margin-right: 20px;
    }
  }
}
