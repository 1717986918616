/*============================================================================================================
	base
============================================================================================================*/

body {
	color: #000;
		-webkit-text-size-adjust: 100%;
	font-weight: normal;
	line-height: 1.8;
	letter-spacing: normal;
}

div,h1,h2,h3,h4,h5,h6,p,ul,ol,li,dl,dt,dd,th,td,section,article,header,footer,main,nav,span,a {
	box-sizing: border-box;
}

a {
	color: inherit;
	text-decoration: none;
}

img {
	width: 100%;
	height: auto;
	vertical-align: bottom;
}

h1,h2,h3,h4,h5,h6 {
	line-height: 1.5;
	font-weight: normal;
	margin: 0;
	word-wrap: break-word;
}

p,li,td,th,dt,dd {
	font-size: 18px;
	margin: 0;
	word-wrap: break-word;
	@include mq {
		font-size: 14px;
	}
}

table {
	width: 100%;
	border-collapse: collapse;
	table-layout: fixed;
}

th,td {
	text-align: left;
	font-weight: normal;
	vertical-align: top;
}

ul,ol,dl {
	padding: 0;
	margin: 0;
}

li {
	list-style: none;
}

.cf:after {
	content: "";
	display: block;
	clear: both;
}


@include mq(pc) {
	.sp {
		display: none !important;
	}
}

@include mq {
	.pc {
		display: none !important;
	}
}


/*------------------------------------------
	font
------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Noto+Serif+JP:wght@400;500;600&display=swap');

body, .f-gothic {
	font-family: $gothic;
}

.f-serif {
	font-family: $mincho;
}

.noto-sans {
	font-family: 'Noto Sans JP', sans-serif;
}

.noto-serif {
	font-family: 'Noto Serif JP', serif;
}


/*------------------------------------------
	frame
------------------------------------------*/
body {
	position: relative;
	min-width: initial;
	overflow: hidden;
	@include mq(pc) {
		min-width: 1200px;
	}
}


/*------------------------------------------
	hover
------------------------------------------*/
@include mq(pc) {
	a {
		cursor: pointer;
		transition: all .3s ease-in-out;
		&:hover {
      color: #E6E6E6;
		}
		&:active {
			color: #393939;
		}
		&.is_btn_hover {
			&:hover {
				background: rgba(#fff, .2);
			}
			&:active {
				background: #000;
				border: solid 1px rgba(#CECECE, .4);
				color: #fff;
			}
		}
	}

	div input[type="submit"] {
		background: #0F1113;
		cursor: pointer;
		transition: all .3s ease-in-out;
		&:hover {
			background: rgba(#fff, .2);
		}
		&:active {
			background: #000;
			border: solid 1px rgba(#CECECE, .4);
			color: #fff;
		}
	}
}


/* imgFitBox imgLinkBox */
.imgFitBox {
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover; object-position: center;';
  }
  &.contain {
    /* fit = contain */
    img {
      object-fit: contain;
      font-family: 'object-fit: contain; object-position: center;';
    }
  }
}
.imgLinkBox {
  .imgLink-target {
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover; object-position: center;';
      transition: all .5s ease;
      transform: scale(1);
    }
  }
  &:hover {
    opacity: 1;
    .imgLink-target {
      overflow: hidden;
      img {
        transition: all .5s ease;
        transform: scale(1.1);
      }
    }
  }
}
